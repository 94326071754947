<template>
  <div class="footer-spacer" ref="footer_spacer">
    <div ref="footer" class="footer fixed w-full bottom-0 rounded-t-2xl" style="max-width: 657px" :style="`background: ${$store.getters.c_background}`">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    recalc(){
      this.$refs.footer_spacer.style.height = `${this.$refs.footer.clientHeight}px`;
      this.$refs.footer.style.maxWidth = `${this.$refs.footer_spacer.parentElement.clientWidth}px`
    }
  },
  mounted(){
    //this.$refs.spacer.style.height = `${this.$refs.footer.clientHeight}px`;
    // setTimeout(this.recalc, 1000)
    this.recalc()
    
    
    window.addEventListener('resize', this.recalc)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.recalc)
  }
}
</script>

<style scoped>
.footer{
  -webkit-box-shadow: 0px -7px 13px -10px rgba(34, 60, 80, 0.6);
  -moz-box-shadow: 0px -7px 13px -10px rgba(34, 60, 80, 0.6);
  box-shadow: 0px -7px 13px -10px rgba(34, 60, 80, 0.6);
}
</style>