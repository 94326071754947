<template>
  <div id="dish_scroller" class="overflow-y-auto h-full relative">
    <div id="image_overlay" v-if="!dish.image_not_required" class="relative flex justify-between shadow" style="height: 350px; -o-background-size: cover; -moz-background-size: cover; -webkit-backgound-size: cover;">
      <div class="px-3 h-full">
        <button class="sticky top-4 mb-4">
          <BackIcon @click="() => {if ($route.meta.back_ref) $router.replace($route.meta.back_ref)}" outlined/>
        </button>
      </div>

      <div class="px-3 h-full">
        <div class="sticky top-4 right-4 ml-auto" >
          <div class="flex flex-col items-end justify-end">
            <NewIcon class="mb-4" v-if="dish.new"/>
            <HITIcon class="mb-4" v-if="dish.bestseller"/>
            <DiscountIcon class="mb-4" v-if="dish.sale"/>
          </div>
        </div>
      </div>
    </div>
    
    <div class="p-2" :class="{'rounded-t-md': !dish.image_not_required}" :style="`background: ${$store.getters.c_background}; min-height: 350px`">
      <div v-if="dish.image_not_required" class="flex justify-between items-center mt-3">
        <button>
          <BackIcon @click="() => {if ($route.meta.back_ref) $router.replace($route.meta.back_ref)}" outlined dark/>
        </button>
      </div>

      <div class=" text-lg font-bold"><Locale :strings="dish.locale_altnames" :default="dish.name"/></div>
      
      <div v-if="dish.volume && dish.volume_measure_unit" class="text-sm mt-1">{{dish.volume}} {{get_volume_label(dish.volume_measure_unit)}}</div>

      <div class="flex justify-start items-center my-4" v-if="dish.tags.length > 0" style="overflow: hidden">
        <DishTag :tag="tag" v-for="tag in dish.tags" :key="tag.id" :style="`margin-right: ${.0 - dish.tags.length * .06}em`"/>

        <!-- <HotIcon class="mr-4" labeled v-if="dish.tags.reduce((val, el) => val || el.id == 1, false)"/>
        <HalalIcon class="mr-4" labeled v-if="dish.tags.reduce((val, el) => val || el.id == 2, false)"/>
        <VeganIcon class="mr-4" labeled v-if="dish.tags.reduce((val, el) => val || el.id == 3, false)"/>
        <DietIcon class="mr-4" labeled v-if="dish.tags.reduce((val, el) => val || el.id == 4, false)"/> -->
        
      </div>

      <hr v-if="dish.tags.length > 0" class="h-1 w-full my-4" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

      <!-- <div v-if="dish.description"> -->
        <!-- <div class="text-sm font-bold mb-3"><T>dish-description</T></div> -->
        <!-- v-html="dish.description" -->
        <pre class="text-sm font-sans max-w-full whitespace-pre-wrap"><Locale :strings="dish.locale_descriptions" :default="''"/></pre>
      <!-- </div> -->

      <div v-if="dish.cook_time" class="text-sm mt-4"><T>dish-cook-time</T>: {{dish.cook_time}} <T>measure-minute</T></div>

      <hr v-if="dish.cook_time" class="h-1 w-full my-4" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

      <div v-if="show_values">
        <div class="text-sm font-bold mb-3"><T>dish-energy-value</T></div>
          
        <div>
          <div class="text-sm flex justify-between items-center"><span><T>dish-proteins</T></span><span>{{proteins}} <T>measure-g</T></span></div>
          <div class="text-sm flex justify-between items-center"><span><T>dish-fats</T></span><span>{{fats}} <T>measure-g</T></span></div>
          <div class="text-sm flex justify-between items-center"><span><T>dish-carbohydrates</T></span><span>{{carbohydrates}} <T>measure-g</T></span></div>
          <div v-if="energy_value" class="text-sm flex justify-between items-center"><span><T>dish-calorie</T></span><span>{{energy_value}} <T>measure-kcal</T></span></div>
        </div>
        
      </div>

      <hr v-if="show_values" class="h-1 w-full my-4" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      

      <ModifierSchemeView v-if="dish.modifier_scheme" @validity="el => invalid_modi_groups = set_validity(invalid_modi_groups, el)" @append="on_append_modifiers" @remove="on_remove_modifiers" :scheme_id="+dish.modifier_scheme" :modifiers="modifiers"/>

      <DishComment v-if="$store.getters.webapp_config.dish_comments" v-model="comment" />
    </div>

    <FixedFooter>
      <button class="flex justify-center items-center text-xl font-bold w-full py-5 rounded-md" @click="buy_click" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <svg class="absolute right-5 top-6" style="width:24px;height:24px; scale: 1.5" viewBox="0 0 24 24">
          <path fill="currentColor" d="M10 0V4H8L12 8L16 4H14V0M1 2V4H3L6.6 11.6L5.2 14C5.1 14.3 5 14.6 5 15C5 16.1 5.9 17 7 17H19V15H7.4C7.3 15 7.2 14.9 7.2 14.8V14.7L8.1 13H15.5C16.2 13 16.9 12.6 17.2 12L21.1 5L19.4 4L15.5 11H8.5L4.3 2M7 18C5.9 18 5 18.9 5 20S5.9 22 7 22 9 21.1 9 20 8.1 18 7 18M17 18C15.9 18 15 18.9 15 20S15.9 22 17 22 19 21.1 19 20 18.1 18 17 18Z" />
        </svg>

        <span class="relative">
          <sup v-if="dish.previous_price" class="absolute text-sm opacity-50 line-through mr-1" style="transform:translateX(-100%); left: -10px; top: 0">{{money(dish.previous_price)}}</sup>
          {{money(portion_sum(portion)/100)}}
        </span>
        
      </button>      
    
    </FixedFooter>

    

    
    
    <transition duration="300" name="from-bottom">
      <router-view @resolve="combo_modi_resolve" @reject="combo_modi_reject" />
    </transition>
  
  </div>
</template>

<script>

import FixedFooter from '../../views/tools/FixedFooter.vue'
// import HotIcon      from '../../icons/HotIcon.vue'
// import VeganIcon    from '../../icons/VeganIcon.vue'
// import DietIcon     from '../../icons/DietIcon.vue'
// import HalalIcon    from '../../icons/HalalIcon.vue'
import DishTag from './DishTag.vue'
import HITIcon      from '../../icons/HITIcon.vue'
import DiscountIcon from '../../icons/DiscountIcon.vue'
import NewIcon from '../../icons/NewIcon.vue'
import BackIcon    from '../../icons/BackIcon.vue'
import ModifierSchemeView from '../../components/dish/modifiers/ModifierSchemeView.vue'
import dish_image from '../../mixins/dish_image'
import modifiers from '../../mixins/modifiers'
import route_back from '../../mixins/route_back'
import sum_counter from '../../mixins/sum_counter'
import volume_parser from '../../mixins/volume_parser'
import sum_formatter from '../../mixins/sum_formatter'
import DishComment from './DishComment.vue'

export default {
  mixins:[dish_image, modifiers, route_back, sum_counter, volume_parser, sum_formatter],
  computed:{
    dish(){
      return this.$store.getters.menu_item_by_id(+this.$route.params.dish)
    },
    proteins(){
      return this.dish.proteins ? this.dish.proteins : 0 
    }, 
    fats(){
      return this.dish.fats ? this.dish.fats : 0 
    }, 
    carbohydrates(){
      return this.dish.carbohydrates ? this.dish.carbohydrates : 0
    },
    energy_value(){
      return this.dish.energy_value ? this.dish.energy_value : 0
    },
    show_values(){
      return this.proteins > 0 || this.fats > 0 || this.carbohydrates > 0 || this.energy_value > 0
    },
    portion(){
      return {
        id: new Date().getTime(),
        dish: this.dish,
        count: 1,
        comment: this.comment,
        modifiers: this.modifiers.map(el => {
          return {
            id: el.id,
            name: el.name,
            type: el.type,
            count: el.count,
            price: el.price,
            modifiers: el.modifiers,
          }
        })
      }
    }
  },
  components:{
    // HotIcon,
    // VeganIcon,
    // DietIcon, 
    // HalalIcon,
    DishTag,
    HITIcon,
    DiscountIcon,
    BackIcon,
    ModifierSchemeView,
    NewIcon,
    FixedFooter,
    DishComment
  },
  data(){
    return {
      scroller: undefined,
      comment: ''
    }
  },
  methods:{
    buy_click(){
      this.check_modifiers('dish_scroller')
        .then(() => {
          if (this.$store.getters.webapp_config.simple_order){
            this.$store.commit('clear_cart')
            this.$store.commit('append_cart', this.portion)
            this.$router.replace('/checkout')
          } else {
            this.$store.commit('append_cart', this.portion)
            this.go_back()
          }
        })
    },
    combo_modi_resolve(modifiers){
      this.submodifiers = modifiers
      this.combo_modi_reject()
    },
    combo_modi_reject(){
      window.history.back()
    },
    setupParallax() {
      let scroller = document.getElementById('dish_scroller')
      let overlay = document.getElementById('image_overlay')
      // alert(scroller.scrollHeight)
      // scroller.setAttribute('style', `background: #343434 url(${this.get_media_link(this.dish.image)}) center ${scroller.offsetTop}px no-repeat fixed;`)
      // scroller.setAttribute('style', `background: ${this.$store.getters.c_background} url(${this.get_media_link(this.dish.image, 'lg')}) center ${scroller.offsetTop-50}px no-repeat fixed;`)
      overlay.style.height = '350px';
      overlay.style.background = `${this.$store.getters.c_background} url(${this.get_media_link(this.dish.image, 'lg')})`
      overlay.style.backgroundPositionX = 'center'
      // overlay.style.backgroundPositionY = `${scroller.offsetTop-50}px`
      overlay.style.backgroundRepeat = 'no-repeat'
      overlay.style.backgroundSize = 'cover'
        
      // overlay.setAttribute('style', `height: 350px ;background: ${this.$store.getters.c_background} url(${this.get_media_link(this.dish.image, 'lg')}) center ${scroller.offsetTop-50}px no-repeat;`)
      
      // if (this.$store.getters.webapp_config.dish_rows){
      //   scroller.style.backgroundSize = `${scroller.clientWidth}px auto` 
      // } else {
      //   scroller.style.backgroundSize = "auto 350px"
      // }
      
      let onscroll = e => {
        
        overlay.style.backgroundPositionY = `${e.target.offsetTop - 50 - (-1 *  (e.target.scrollTop / 2))}px`
        // overlay.style.filter = `blur(${e.target.scrollTop / 150}px)`
        // e.target.style.backgroundPosition = `center ${e.target.offsetTop - 50 + (-1 *  (e.target.scrollTop / 2))}px`
        // e.target.style.backgroundFilter = `blur()`
        // document.getElementById('image_overlay').style.backdropFilter = `blur(${e.target.scrollTop/150}px)`
      }
      
      scroller.addEventListener('scroll', onscroll)

      return onscroll
    }
  },
  async mounted(){
    this.modifiers = []
    this.$nextTick(() => {
      this.scroller = this.setupParallax()
    })
  },
  beforeDestroy(){
    document.getElementById('dish_scroller').removeEventListener('scroll', this.scroller)
  }

}
</script>

<style scoped>
.shadow {
  -webkit-box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.63) inset;
  -moz-box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.63) inset;
  box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.788) inset;
}
.gradient {
  background-color: white;
}

.from-bottom-enter-active {
  transition: all .3s ease-in-out;
  transform: translateY(-10%) scale(.3);
  opacity: .0;
}

.from-bottom-enter-to {
  transform: translateY(0%) scale(1);
  opacity: 1;
}

.from-bottom-leave-active {
  transition: all .3s ease-in-out;
  transform: translateY(0) scale(1);
  opacity: 1;
}

.from-bottom-leave-to {
  transform: translateY(-10%) scale(.3);
  opacity: .0;
}

</style>