

export default {
  methods:{
    get_media_link(media, size='lg'){
      // let url = 'http://127.0.0.1:5501'
      let url = this.$store.getters.url
      return media ? `${url}/proxypass/${this.$store.getters.appuid}/media/images/${media}?size=${size}` : `${url}/proxypass/${this.$store.getters.appuid}/media/images/placeholder.png`
    },
    get_icons_link(media){
      // let url = 'http://127.0.0.1:5501'
      let url = this.$store.getters.url
      return media ? `${url}/proxypass/${this.$store.getters.appuid}/media/icons/${media}` : `${url}/proxypass/${this.$store.getters.appuid}/media/images/placeholder.png`
    },
  }

}