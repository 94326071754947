<template>
  <div class="fixed z-10 flex justify-center items-center" :style="`top: -50px; left: -50px; right: -50px; bottom: -50px; padding: 50px; background-color: ${$store.getters.c_background}A0`">
    <div @click.prevent="()=>{}" @scroll.prevent="()=>{}" class="modal-container w-full h-full sm:max-w-md sm:shadow-2xl" style="max-height: 900px" :style="`background: ${$store.getters.c_background}`">
      <transition duration="300" name="fade">
        <router-view @resolve="e => $emit('resolve', e)" @reject="e => $emit('reject', e)" v-if="delayed"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DishVerbose',
  data(){
    return {
      delayed: false
    }
  },
  mounted(){
    this.$store.dispatch('modal_inc')
    setTimeout(() => {
      this.delayed = true
    }, 30)
  },
  beforeDestroy(){
    this.$store.dispatch('modal_dec')
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity .3s ease-in-out;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
</style>