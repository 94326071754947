<template>
  <v-component 
    :is="source.descriminator == 'category' ? 'CategViewer' : 'Dish'"
    :dish="source"
    :category="source"
  >

  </v-component>
</template>

<script>

import Dish from '../../components/dish/Dish.vue'
import CategViewer from '../../components/categ/CategViewer.vue'
export default {
  name: 'DisplayProxy',
  props: {
    index: { // index of current item
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components:{
    Dish,
    CategViewer
  }
}
</script>