export default {
  state: {
    custom_info: {
      main_page_info: [],
      invoice_page_info: []
    }
  },
  mutations: {
    set_custom_info(state, value) {
      state.custom_info = value
    }
  },
  actions: {
    async load_custom_info({dispatch, commit}) {
      await commit('set_custom_info', await dispatch('api_request', {method: '/custominfo'}))
    }
  },
  getters: {
    custom_info(state) {
      return state.custom_info
    }
  }
}