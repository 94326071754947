<template>
  <li class="cart-item w-full">
    <div class="flex justify-between items-start w-full">
      <div>
        <div class="font-bold">
          <Locale :strings="portion.dish.locale_altnames" :default="portion.dish.name"/>
        </div>
        <div class="italic font-thin" style="font-size: .7em; opacity: .8">
          {{portion.comment}}
        </div>
      </div>
      <div class="flex flex-col items-end">
        <span class="font-bold text-md whitespace-nowrap mr-3">={{money(portion_sum(portion)/100)}}</span>
        <Counter :value="portion.count" @inc="$emit('inc')" @dec="$emit('dec')" :uplimit="portion.dish.rests > 0 ? portion.dish.rests/1000 : 10"/>
      </div>
    </div>
    <CartModifier v-for="modifier in portion.modifiers" :key="modifier.id" :modifier="modifier"/>
    
  </li>
</template>

<script>
import Counter from '../../components/ui/Counter.vue'
import CartModifier from './CartModifier.vue'
import sum_counter from '../../mixins/sum_counter'
import sum_formatter from '../../mixins/sum_formatter'

export default {
  mixins:[sum_counter, sum_formatter],
  props:['portion'],
  components:{
    Counter,
    CartModifier
  },
  
}
</script>

<style scoped>
.cart-item {
  transition: all .2s ease-in-out;
  /* display: inline-block; */
}
</style>