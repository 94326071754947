<template>
  <div class="block justify-center items-center font-bold rounded-md py-2 px-1 text-sm" :class="{'text-xs': small, 'max-h-9': small}" :style="`background: ${$store.getters.c_background}`">
    <T>tag-hit</T>
  </div>
  <!-- <svg v-if="small" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.332031 3.77778C0.332031 1.69137 1.85426 0 3.73203 0H30.932C32.8098 0 34.332 1.69137 34.332 3.77778V30.2222C34.332 32.3086 32.8098 34 30.932 34H3.73203C1.85426 34 0.332031 32.3086 0.332031 30.2222V3.77778Z" fill="white"/>
    <path d="M8.14952 16.8327L5.50732 11.8262H7.74303L9.20639 14.7661L9.53159 15.7994L9.84323 14.7661L11.3743 11.8262H13.3933L10.6562 16.7308L13.5288 22.0139H11.3202L9.65354 18.8703L9.28769 17.7933L8.9354 18.8703L7.25524 22.0139H5.23633L8.14952 16.8327Z" fill="#343434"/>
    <path d="M18.5261 18.6956L18.5938 17.6623H18.5532L17.9705 18.7247L15.5587 22.0139H14.4476V14.737H16.2091V18.2008L16.1278 19.1904H16.182L16.7375 18.1571L19.1764 14.737H20.2875V22.0139H18.5261V18.6956Z" fill="#343434"/>
    <path d="M26.709 16.4107H24.7985V22.0139H22.9829V16.4107H21.0723V14.737H26.709V16.4107Z" fill="#343434"/>
    <path d="M28.0771 11.8262H29.8928V16.6144L29.5405 18.9867H28.4294L28.0771 16.6144V11.8262ZM27.8874 21.0533C27.8874 20.7138 27.9868 20.4469 28.1855 20.2529C28.3843 20.0491 28.6462 19.9473 28.9714 19.9473C29.3147 19.9473 29.5857 20.0491 29.7844 20.2529C29.9831 20.4469 30.0825 20.7138 30.0825 21.0533C30.0825 21.3929 29.9831 21.6646 29.7844 21.8684C29.5857 22.0721 29.3147 22.174 28.9714 22.174C28.6462 22.174 28.3843 22.0721 28.1855 21.8684C27.9868 21.6646 27.8874 21.3929 27.8874 21.0533Z" fill="#343434"/>
  </svg>

  <svg v-else width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="36" rx="4" fill="white"/>
    <path d="M8.98281 18.304L5.86281 12.8H8.50281L10.2308 16.032L10.6148 17.168L10.9828 16.032L12.7908 12.8H15.1748L11.9428 18.192L15.3348 24H12.7268L10.7588 20.544L10.3268 19.36L9.91081 20.544L7.92681 24H5.54281L8.98281 18.304ZM21.2358 20.352L21.3158 19.216H21.2678L20.5798 20.384L17.7318 24H16.4198V16H18.4998V19.808L18.4038 20.896H18.4678L19.1238 19.76L22.0038 16H23.3158V24H21.2358V20.352ZM30.8986 17.84H28.6426V24H26.4986V17.84H24.2426V16H30.8986V17.84ZM32.5141 12.8H34.6581V18.064L34.2421 20.672H32.9301L32.5141 18.064V12.8ZM32.2901 22.944C32.2901 22.5707 32.4074 22.2773 32.6421 22.064C32.8767 21.84 33.1861 21.728 33.5701 21.728C33.9754 21.728 34.2954 21.84 34.5301 22.064C34.7647 22.2773 34.8821 22.5707 34.8821 22.944C34.8821 23.3173 34.7647 23.616 34.5301 23.84C34.2954 24.064 33.9754 24.176 33.5701 24.176C33.1861 24.176 32.8767 24.064 32.6421 23.84C32.4074 23.616 32.2901 23.3173 32.2901 22.944Z" fill="#343434"/>
  </svg> -->
</template>

<script>
export default {
  props: {
    small: Boolean
  }
}
</script>