<template>
<Loader v-if="preloading" :value="true" style="position: fixed; top: 50vh; left: 50vw; transform: translate3d(-50%, -50%, 0)"/>
<div class="w-full overflow-x-hidden relative" style="max-width: 657px; min-height: 100vh" v-else>
  <ModalContainer :value="isThrownException" @input="hide_error">
    <template #header>
      <span v-html="thrownException.title"></span>
    </template>

    <span v-html="thrownException.text"></span>
  </ModalContainer>


  
  <transition duration="300" :name=slideDirection>
    <router-view/>
  </transition>
</div>

  
</template>

<script>
import modals from '../mixins/modals'
import Loader from '../icons/MenuLoading.vue'
import {mapGetters, mapMutations} from 'vuex'

export default {
  mixins:[
    modals
  ],
  methods:{
    ...mapMutations(['hide_error']),
    appendMeta(name, content){
      let meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      document.getElementsByTagName('head')[0].appendChild(meta);
    },
    async loadTheme(){
      await this.$store.dispatch('load_theme')
      document.body.style.backgroundColor = this.$store.getters.c_background
      document.body.style.color = this.$store.getters.c_text_basic
      document.body.style.overflowX = 'hidden'
      this.appendMeta('theme-color', this.$store.getters.c_background)
      this.appendMeta('msapplication-navbutton-color', this.$store.getters.c_background)
      this.appendMeta('apple-mobile-web-app-status-bar-style', this.$store.getters.c_background)
    }
  },
  computed:{
    ...mapGetters(['isThrownException','thrownException', 'popup_data'])
  },
  data(){
    return {
      slideDirection: 'slide-left',
      preloading: true,
      show_welcome_popup: false
    }
  },
  watch:{
    $route(to, from) {
      const toDepth = to.matched.reduce((idx, rt)=> rt.meta.page_index || idx, 0)
      const fromDepth = from.matched.reduce((idx, rt)=> rt.meta.page_index || idx, 0)
      
      this.slideDirection =  toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  },
  components:{
    Loader
  },
  async mounted(){
    
    try {
      await Promise.all([
        this.loadTheme(),
        this.$store.dispatch('get_menu_reference'), 
        this.$store.dispatch('get_screen_buttons'),
        this.$store.dispatch('load_popup_data', this.$store.getters.webapp_config.welcome_popup),
        this.$store.dispatch('get_socials'),
        this.$store.getters.webapp_config.custom_info ? this.$store.dispatch('load_custom_info') : new Promise(r => r())
      ]) 

    } finally {
      this.preloading = false

    }

    
  }
}
</script>

<style>
.slide-left-enter-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all .3s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .3s ease-in-out;
  
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all .3s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .3s ease-in-out;
  transform: translateX(0%);
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}


</style>