<template>
  <div id="app" class="w-screen flex justify-center sticky overflow-x-hidden">
    <!-- <button @click="$store.dispatch('set_locale', 'ru')" style="position: fixed; top: 0; left:0; background-color:black; color:red; z-index: 100000">
      reset locale
    </button> -->
    <!-- <div class="relative container w-full" style="max-width: 657px"> -->
    <component :is="layout"/>
    <!-- </div> -->
  </div>
</template>

<script>
import Basic from './layouts/Basic.vue'
import ScanQRFirst from './layouts/ScanQRFirst.vue'

export default {
  computed: {
    layout(){
      // return 'ScanQRFirst'
      return this.$store.getters.isBasicsPassed ? 'Basic' : 'ScanQRFirst'
      // return !this.$store.getters.isBasicsPassed ? 'ScanQRFirst': this.$route.meta.layout || 'Basic' 
    }
  },
  components:{
    Basic,
    ScanQRFirst
  },
  async beforeCreate(){
    this.$store.dispatch('load_storage')
    await this.$store.dispatch('load_current_locale')
  },
  async mounted(){
    
    // document.documentElement.classList.add('dark')
  }

}
</script>

<style>
*:focus, *:active {
  -webkit-tap-highlight-color: transparent !important;
}
*:active {
  background: initial;
}

.shake_it {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

button{
  transition: all .1s ease-in-out;
}

button:focus, button:active {
  border: none !important;
  outline: none !important;
}

button:active {
  filter:brightness(1.1);
  opacity: .8;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(3px);
  }
  
  20%, 80% {
    transform: translateX(7px);
  }

  30%, 50%, 70% {
    transform: translateX(0px);
  }

  40%, 60% {
    transform: translateX(8px);
  }
}

</style>