<template>
  <div :id="`cat${category.id}`" class="category-container mt-5">
    <div class="mb-3 mx-2">
      <span class="text-3xl"><Locale :strings="category.locale_names" :default="category.name"/></span>
      <hr class="h-1" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
    </div>
    <DishMosaic :categ="category.id" />
    
  </div>
</template>

<script>
import DishMosaic from '../dish/DishMosaic.vue'

export default {
  props:['category'],
  components:{
    DishMosaic
  }
  
}
</script>