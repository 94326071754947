import T from './T.vue'
import Str from './String.vue'
import Vue from 'vue'

Vue.component('T', T)
Vue.component('Locale', Str)

Vue.prototype.$t = function (phrase_code) {
  let phrase = this.$store.getters.current_locale_retreive_phrase(phrase_code)
  if (phrase) {
    return phrase
  } else {
    return `\${${phrase_code}}$`
  }
}