<template>
  <div class="w-full h-full flex flex-col items-center justify-center p-3">
    <div class="max-w-xl flex flex-col items-center justify-between h-48">
      <span class="text-2xl font-bold"><T>away-wait-a-minute</T></span>
      <ContentLoading :value="true" />
      <span class="text-md font-bold"><T>away-payment-redirect</T></span>


    </div>

  </div>
  
</template>

<script>
import ContentLoading from '../icons/ContentLoading.vue'

export default {
  components:{
    ContentLoading
  }
  
}
</script>