import smooth_scroll from './smooth_scroll'
import { mapGetters } from 'vuex'

export default {
  mixins:[smooth_scroll],
  data(){
    return {
      modifiers:[],
      invalid_modi_groups: [],
      submodis: undefined
    }
  },
  computed:{
    ...mapGetters(['menu_modifiers_of_scheme'])
  },
  methods:{
    scheme(idx){
      if (idx) {
        let tmp = this.menu_modifiers_of_scheme(idx)

        if (tmp) {
          return [...tmp]
        } else return []
        
      } else return []
    },
    async check_modifiers(scroller_id){
      if (this.invalid_modi_groups.length > 0) {
        await this.scroll(scroller_id, {
          y: document.getElementById(`group${this.invalid_modi_groups[0].id}`).offsetTop - 50
        }, 50)

        await this.shake_invalid_groups()

        throw new Error('invalid')
      } else return 0
    },
    shake_invalid_groups(){
      this.invalid_modi_groups.forEach(el => {
        let cat = document.getElementById(`group${el.id}`)

        cat.classList.add('shake_it')

        setTimeout(() => {
          cat.classList.remove('shake_it')
        }, 820)          
      })
    },
    async on_append_combo_modi(edit){
      this.combo_modi.modifiers = await this.on_append(this.combo_modi.modifiers, edit)
    },
    async on_remove_combo_modi(edit){
      this.combo_modi.modifiers = await this.on_remove(this.combo_modi.modifiers, edit)
    },
    async on_append_modifiers(edit){
      this.modifiers = await this.on_append(this.modifiers, edit)
    },
    async on_remove_modifiers(edit){
      this.modifiers = await this.on_remove(this.modifiers, edit)
    },
    async on_append(arr, {group, modifier}){
      let tmp = [...arr]

      if (group.upper_limit){
        if (group.total_count + 1 > group.upper_limit) { 
          let victim
          if (Math.random() > 0.5) {
            victim = group.bound_modifiers.slice(-1)[0]
          } else {
            victim = group.bound_modifiers[0]
          }

          tmp = this.do_remove(tmp, victim)
        }
      }

      if (modifier.modifier_scheme && !modifier.submodis_resolved){
        try {
          modifier = await this.do_show_combo_modi(modifier)
        } catch { 
          return tmp
        }
      }
      return this.do_append(tmp, modifier)
    },
    async on_remove(arr, {modifier}){
      return this.do_remove(arr, modifier)
    },
    do_append(arr, modifier){
      if (modifier.count == 0) {
        modifier.count += 1
        return [...arr, modifier]
      } else {
        return arr.filter(el => {
          if (el.id == modifier.id) {
            el.count = modifier.count + 1
          }
          return true
        })
      }
    },
    do_remove(arr, modifier){
      if (modifier.count == 1) {
        return arr.filter(el => el.id != modifier.id)      
      } else {
        return arr.filter(el => {
          if(el.id == modifier.id) {
            el.count = modifier.count - 1
          }
          return true
        })
      }
    },
    set_validity(original_array, group){
      let arr = [...original_array]

      arr = arr.filter(el => el.id != group.id)
      if (!group.validity) {
        arr.push(group)
      }

      return arr
      
    },
    do_show_combo_modi(modi){

      // console.log(this.scheme(modi.modifier_scheme))
      if (this.scheme(modi.modifier_scheme).length == 0){
        
        return new Promise(resolve => resolve({...modi, modifiers: [], submodis_resolved: true})) 
      }

      this.submodifiers = undefined

      this.$router.push(`combo/${modi.modifier_scheme}`)

      return new Promise((resolve, reject) => {  
        
        const wait_for_resolve = () => {
          // if (!this.show_combo_modi){
          if (this.$route.name != 'ComboModifierView') {
            if (this.submodifiers) {
              resolve({...modi, modifiers: this.submodifiers, submodis_resolved: true})
            } else {
              reject(new Error('Aborted by user'))
            }
          } else {
            setTimeout(wait_for_resolve, 500)
          }
        }        

        wait_for_resolve()
      })

    }
  },


}
