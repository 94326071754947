export default {
  state: {
    awaiting: true,
    failed: false,
    categories: [],
    items: [],
    modifier_schemes: [],
    screen_buttons: [],
    focused_category: undefined
  },
  mutations: {
    commit_screen_buttons(state,value) {
      state.screen_buttons = value
    },
    commit_categories(state, value) {
      state.categories = value
    },
    commit_items(state, value) {
      state.items = value
    },
    commit_modifiers(state, value) {
      state.modifier_schemes = value
    },
    set_menu_awaiting(state, value) {
      state.awaiting = value
    },
    set_menu_failed(state, value) {
      state.failed = value
    },
    commit_focused_category(state, value){
      state.focused_category = value
    }
  },
  actions: {
    async set_focused_category({commit}, value){
      commit('commit_focused_category', value)
      await new Promise(resolve => setTimeout(resolve, 100))
      //commit('commit_categories', [...state.categories])
      // state.focused_category = value
      // state.categories = [...state.categories]
    },
    async get_menu_reference({dispatch, commit}) {
      commit('set_menu_awaiting', true)
      try {
        let r = await dispatch('api_request', {'method': '/menu'})
        commit('commit_categories', r.categories)
        commit('commit_items', r.items)
        commit('commit_modifiers', r.modifier_schemes)
        commit('set_menu_awaiting', false)
      } catch (err) {
        commit('set_menu_awaiting', false)
        commit('set_menu_failed', true)
      }
    },
    async get_screen_buttons({dispatch, commit}) {
      commit('set_menu_awaiting', true)
      try {
        let r = await dispatch('api_request', {'method': '/screen_buttons'})
        commit('commit_screen_buttons', r)
        commit('set_menu_awaiting', false)
      } catch (err) {
        commit('set_menu_awaiting', false)
        commit('set_menu_failed', true)
      }
    }
  },
  getters: {
    screen_buttons(state){
      return state.screen_buttons
    },
    menu_awaiting(state){
      return state.awaiting
    },
    menu_failed(state){
      return state.failed
    },
    menu_categories(state, getters){
      return [
        {
          id: 'sale',
          name: getters.current_locale_retreive_phrase_with_placeholder('categ-sale')          
        },
        {
          id: 'bestseller',
          name: getters.current_locale_retreive_phrase_with_placeholder('categ-bestseller')          
        },
        {
          id: 'new',
          name: getters.current_locale_retreive_phrase_with_placeholder('categ-new')          
        },
        ...(state.categories ? state.categories : []),
      ].filter( el => getters.menu_items_of_category(el.id).length > 0)
    },
    // menu_categories(state){
    //   return state.categories ? state.categories : []
    // },
    menu_categories_threadmill(state, getters){
      if (!state.focused_category){
        state.focused_category = getters.menu_categories[0].id
      }

      // find index of focused category
      let focused_index = 0
      for (let i = 0; i < getters.menu_categories.length; i++) {
        if (state.focused_category == getters.menu_categories[i].id) {
          focused_index = i
          break
        }
      }

      // console.log('focused index', focused_index)

      return getters.menu_categories.filter((_, idx) => {
        //return [focused_index - 1, focused_index, focused_index + 1].filter( el => el == idx).length > 0
        return idx <= focused_index + 1 
      })
    },
    menu_category_by_id(state){
      return id => { 
        return state.categories.filter( el => {return +el.id == +id})[0] 
      }
    },
    menu_item_by_id(state){
      return id => { 
        return state.items.filter( el => {return +el.id == +id})[0] 
      }
    },
    menu_items(state){
      return state.items ? state.items.filter(el => el.rests != 0) : []
    },
    menu_items_sorted(state, getters) {
      return getters.menu_items.sort((el1, el2) => el1.sort_order - el2.sort_order)
    },
    menu_items_of_category(state, getters){
      return id => { 
        if (id == 'new') {
          return getters.menu_items_sorted.filter(el => el.new)
        } else if (id == 'bestseller') {
          return getters.menu_items_sorted.filter(el => el.bestseller)
        } else if (id == 'sale') {
          return getters.menu_items_sorted.filter(el => el.sale)
        } else {
          return getters.menu_items_sorted.filter(el => +el.category_id == +id)
        }
      }
    },
    menu_modifiers(state){
      return state.modifiers ? state.modifiers : []
    },
    menu_modifiers_of_scheme(state){
      return id => ( state.modifier_schemes[id] )
    },
    rendered_menu(state, getters){
      let result = []

      getters.menu_categories.forEach(el => {
        result.push({descriminator: 'category', ...el}, ...getters.menu_items_of_category(el.id))
      })
      
      return result
    }
  },
  modules: {
  }
}