export default {
  state:{
    current_locale: 'ru',
    name: '',
    version: 0,
    phrases:{
    }
  },
  mutations:{
    inject_locale_data(state, value){
      state.name = value.name
      state.version = value.version
      state.phrases = value.phrases
    },
    commit_current_locale(state, value) {
      state.current_locale = value
    }
  },
  actions:{
    async load_current_locale({dispatch, commit, getters}){
      let data = await dispatch('api_request', {method:`/locales/${getters.current_locale}`, internal_request: true})

      commit('inject_locale_data', data)
    },
    async set_locale({dispatch, commit}, value){

      commit('commit_current_locale', value)

      await dispatch('load_current_locale')

      localStorage.setItem('qrmenu_selected_locale', value)
    }
  },
  getters:{
    current_locale(state){
      return state.current_locale
    },
    current_locale_name(state){
      return state.name
    },
    current_locale_version(state){
      return state.version
    },
    current_locale_phrases(state){
      return state.phrases
    },
    current_locale_retreive_phrase(state,getters){
      return (phrase_code) => {
        return getters.current_locale_phrases[phrase_code]
      }
    },
    current_locale_retreive_phrase_with_placeholder(state,getters){
      return (phrase_code) => {
        return getters.current_locale_phrases[phrase_code] || `\${${phrase_code}}$`
      }
    }
  }
}