<template>
  <div>
    <Actions class="mb-3"/>
    <Socials class="my-3"/>
    <Legal class="mb-3"/>
  </div>
</template>

<script>
import Actions from './Actions.vue'
import Socials from './Socials.vue'
import Legal from './Legal.vue'

export default {
  components: {
    Actions,
    Socials,
    Legal
  }
  
}
</script>