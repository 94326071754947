<template>
  <div class="mb-4 flex justify-between w-full">
    <div class="mr-4">{{dish.name}}</div>
    <div class="flex w-24 justify-between">
      <div class="mr-4">x{{dish.count}}</div>
      <div class="whitespace-nowrap">={{money(dish.sum)}}</div>
    </div>
    
  </div>
</template>

<script>
import sum_formatter from '../../mixins/sum_formatter'

export default {
  mixins:[sum_formatter],
  props:['dish']
}
</script>