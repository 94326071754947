<template>
  <div class="h-full w-full">
    <FixedHeader :flat="flat_header">
      <div class="flex flex-row justify-between items-center head" >
        <button>
          <BackButton outlined dark @click="$router.replace($route.meta.back_ref)"/>
        </button>
        <span class="text-2xl font-bold"><T>checkout-your-order</T></span>
      </div>
    </FixedHeader>

    <div v-if="!$store.getters.menu_awaiting && $store.getters.webapp_config.custom_info" class="mx-2 italic">
      <hr class="border-1 my-2" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      <div class="flex">
        <HintIcon class="mr-2" style="min-width: 24px" />
        <Locale :strings="custom_info.invoice_page_info" default="" />
      </div>
      <hr class="border-1 my-2" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
    </div>

    <div class="w-full p-2">
        
      <div id="checkout_scroller" class="flex flex-col flex-grow overflow-y-auto relative">
        <Cart class="" />
        
        <hr v-if="cart.length > 0" class="border-1 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <Summary v-if="cart.length > 0" :loading="loading" class="mb-3"/>

        <hr v-if="cart.length > 0" class="border-1 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <!-- <Paymethod v-if="order_allowed" @append="el => paymethod = {...el.modifier, count: 1}" @remove="el => paymethod = undefined" @validity="el => invalid_modi_groups = set_validity(invalid_modi_groups, el)" :value="paymethod"/> -->
        <ChequeRO v-if="$store.getters.orderguid"  />

        <hr v-if="cart.length == 0 && order_allowed && $store.getters.orderguid" class="border-1 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <div v-if="cart.length == 0 && order_allowed && $store.getters.orderguid" class="flex justify-between items-center text-2xl font-bold w-full">
          <span v-if="show_fully_paid"><T>checkout-no-payments-required</T></span>
          <span v-if="!show_fully_paid"><T>checkout-to-be-paid</T></span>
          <span v-if="!show_fully_paid" class="whitespace-nowrap">{{money(order.sum)}}</span>
        </div>

        
        <hr v-if="cart.length == 0 && order_allowed && $store.getters.orderguid" class="border-1 my-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <div v-if="cart.length == 0 && order_allowed && $store.getters.orderguid" class="flex  mb-3">
          <div class="mr-3"><HintIcon /></div>

          
          <span><T>checkout-cash-payment-hint</T></span>
        </div>

        <div v-else>
          <hr v-if="$store.getters.orderguid" class="border-1  my-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
          
          
          <div  class="flex  mb-3">
            <div class="mr-3"><HintIcon /></div>

            
            <span><T>checkout-goto-waiter</T></span>
          </div>

          <hr class="border-1 my-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        </div>

        
        

        <hr v-if="cart.length == 0 && order_allowed && $store.getters.orderguid" class="border-1 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">


      </div>
    </div>

    <div class="flex flex-col p-2" v-if="show_comment_field">
      <div v-if="comment_field_name">{{ comment_field_name }}</div>
      <T v-else :key='555666'>order-comment</T>

      <textarea 
        class="comment-area border-2" 
        style="resize: none;padding: 0.8em;outline: none;" 
        :style="`border-color: ${$store.getters.c_accent}${focused_comment ? `ff` : '60'}; background: ${$store.getters.c_background}`"
        maxlength="100"
        :placeholder="comment_field_hint"
        v-on="$store.getters.orderguid ? 
          {} :
          {focus: () => focused_comment = true, blur: () => focused_comment = false } 
        "
        :readonly="$store.getters.orderguid ? true : false"
        :value="comment"
        @change="modify_comment"
      />
    </div>

    <FixedFooter class="text-white">

      <div class="keyholder" v-if="show_fully_paid">
        <button class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
          <T :key="1">checkout-fully-paid</T>
        </button>
      </div>
      
      <div class="keyholder" v-else-if="show_send_order || show_pay_order">
        <button :disabled="button_disabled" v-if="show_send_order" @click="pay_click(false)" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" 
        :style="`background: ${$store.getters.c_accent}${button_disabled ? `60` : 'ff'}; color: ${$store.getters.c_text_accent}`">
          <T :key="2">checkout-send-invoice</T>
        </button>
        <div v-if="show_send_order && show_pay_order" class="w-min mx-auto my-2 "><T :key="6">checkout-or</T></div>
        <button :disabled="button_disabled" v-if="show_pay_order" @click="pay_click(true)" class="text-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" 
        :style="`background: ${$store.getters.c_accent}${button_disabled ? `60` : 'ff'}; color: ${$store.getters.c_text_accent}`">
          <T :key="3">checkout-pay-online</T>
        </button>
      </div>

      <div class="keyholder" v-if="table_needed">
        <button @click="show_table_selection" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
          <T :key="4">select-a-table</T>
        </button>
      </div>

      <div class="keyholder" v-if="webapp_config.use_tips">
        <button @click="get_tipslink" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
          <T :key="5">invoice-leave-a-tip</T>
        </button>
      </div>
    </FixedFooter>

    <transition duration="300" name="from-bottom">
      <router-view/>
    </transition>

    <ModalDialog v-model="showLeaveDialog">
      <template #header>
        <T>about-to-leave-title</T>
      </template>
      <template>
        <T>about-to-leave-body</T>
        <br/>
        {{tipsLink}}
      </template>
      <template #actions>
        <div style="width: 100%;" class="flex justify-between">
          <a :href="tipsLink" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`" target="_blank" @click="showLeaveDialog = false">
              <T>leave-confirm</T>
          </a>
          <button @click.prevent="showLeaveDialog = false" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`">
              <T>leave-cancel</T>
          </button>
        </div>
      </template>
    </ModalDialog>

  </div>
</template>

<script>
import BackButton from '../icons/BackIcon.vue'
import Cart from '../components/cart/Cart.vue'
import ChequeRO from '../components/cart/ChequeRO.vue'
// import Actions from '../components/footer/Actions.vue'
import sum_counter from '../mixins/sum_counter'
import route_back from '../mixins/route_back'
import Summary from '../components/cart/Summary.vue'
// import Paymethod from '../components/cart/Paymethod.vue'
import {mapGetters} from 'vuex'
import modifiers from '../mixins/modifiers'
import HintIcon from '../icons/HintIcon.vue'
import sum_formatter from '../mixins/sum_formatter'
import FixedFooter from './tools/FixedFooter.vue'
import FixedHeader from './tools/FixedHeader.vue'

import flat_header from '../mixins/flat_header'
import ModalDialog from '../components/ui/modal/ModalContainer.vue'

export default {
  mixins:[sum_counter, modifiers, route_back, sum_formatter, flat_header],
  data(){
    return {
      paymethod: undefined,
      loading: false,
      invalid_modi_groups: [{id: 1}],
      focused_comment: false,
      comment: "",
      showLeaveDialog: false,
      tipsLink: ""
    }
  },
  watch:{
    cart(){
      if (this.cart.length  == 0) {
        if (!this.$store.getters.orderguid || this.$store.getters.webapp_config.simple_order) this.go_back()
      } else {
        this.validate()
      }
    }
  },
  methods:{
    async get_tipslink(){
      const link = await this.$store.dispatch('follow_tiplink')
      this.tipsLink = link
      this.showLeaveDialog = true
    },
    perform_invoice(to_be_paid){
      this.loading = true
      
      this.$store.dispatch('perform_invoice', to_be_paid)
      .then( r => {
        if (r.paylink) {
          this.$router.push('/away')
          this.$store.dispatch('follow_paylink', r.paylink)
        } else {
          this.$router.push('/invoice')
        }
      })
      .catch( () => {
        this.loading = false
      })
    },
    async validate(){
      if (this.order_allowed) {
          
        this.loading = true
        try {
          await this.$store.dispatch('validate_cart')
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },
    async check_order(){
      if (this.$store.getters.orderguid) {
        await this.$store.dispatch('check_order')
      }
    },
    async pay_click(to_be_paid){
      try {
        // await this.check_modifiers('checkout_scroller')
        this.perform_invoice(to_be_paid)
        // this.$router.push('/invoice')
      } catch {
        // console.log('payment method is not selected')
      }
    },
    show_table_selection(){
      this.$router.push('/checkout/table')
    },
    modify_comment(event){
      let comment = event.target.value
      event.stopPropagation()
      this.comment = comment
      this.$store.dispatch('modify_order_comment', comment)
    }
  },
  computed:{
    ...mapGetters(['cart', 'order_allowed', 'order', 'webapp_config', 'order_prohibited', 'manual_table', 'tableid', 'custom_info', 'order_comment', 'unsaved_comment']),
    show_fully_paid(){
      return this.order.sum == 0 && this.cart.length == 0
    },
    show_send_order(){

      //return this.cart.length > 0 && this.order_allowed && this.webapp_config.use_offline_payments

      return this.webapp_config.use_offline_payments && this.order_allowed ? this.cart.length > 0 : false
    },
    show_pay_order(){
      // return  (this.webapp_config.simple_order || (this.cart.length == 0 && this.order_allowed && this.$store.getters.orderguid)) && this.webapp_config.use_online_payments

      return this.webapp_config.use_online_payments && this.order_allowed ? this.webapp_config.simple_order ? true : this.cart.length == 0 || !this.webapp_config.use_offline_payments : false

    },
    table_needed(){
      return !this.tableid && this.manual_table && !this.order_prohibited
    },
    show_comment_field() {
      return this.webapp_config.use_order_comment;
    },
    comment_field_name() {
      return this.webapp_config.order_comment_field_name;
    },
    comment_is_mandatory(){
      return this.webapp_config.use_order_comment && this.webapp_config.order_comment_is_mandatory
    },
    comment_field_hint(){
      if (this.$store.getters.orderguid){
        return ""
      }
      return this.comment_is_mandatory ? this.$store.getters.current_locale_retreive_phrase("field-is-mandatory") : ""
    },
    button_disabled(){
      if (this.$store.getters.orderguid){
        return false
      }
      return this.comment_is_mandatory && !(this.order_comment || this.unsaved_comment)
    }
  },
  async mounted(){

    if (this.table_needed) {
      this.show_table_selection()
    }
    
    this.paymethod = {id: 1}
    this.paymethod = undefined

    await this.check_order()

    if (this.cart.length > 0){
      await this.validate()
    }

    window.addEventListener('scroll', this.window_scroll)
    this.comment = this.$store.getters.order_comment
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.window_scroll)
  },
  components:{
    Cart,
    BackButton,
    Summary,
    HintIcon,
    FixedFooter,
    FixedHeader,
    ChequeRO,
    ModalDialog
    // Actions
  }
  
}
</script>

<style scoped>
.keyholder:not(:last-child) {
  margin-bottom: 4px;
}

.from-bottom-enter-active {
  transition: all .3s ease-in-out;
  /*transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0); */
  opacity: .0;
}

.from-bottom-enter-to {
  /* transform: translateY(0%) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-active {
   transition: all .3s ease-in-out;
  /*transform: translateY(0) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-to {
  /* transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0px); */
  opacity: .0;
}

</style>