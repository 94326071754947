<template>
  <div class="h-16 flex rounded-b-xl border-b-2 items-center" :style="`border-color: ${$store.getters.c_accent}`" >
    <!-- :class="{'hidden': !$route.meta.back_ref && webapp_config.available_locales.length <= 1}" -->
    
    <button @click="() => {$router.replace($route.meta.back_ref)}" v-if="$route.meta.back_ref" class="flex justify-center items-center cursor-pointer h-8">
      <BackButton outlined dark class="mx-2"/>
    </button>
    <div class="placeholder h-8 w-8" v-else></div>

    <div class="flex w-full justify-center">
      <span class="text-xl font-bold mx-5">{{breadcrumb}}</span> 
    </div>

    <button v-if="webapp_config.available_locales && (webapp_config.available_locales.length > 1)" @click.prevent="lang_selector=!lang_selector" class="relative flex justify-center border-l-2 items-center h-8 cursor-pointer z-20" :style="`border-color: ${$store.getters.c_text_basic}44`">
      <GlobeIcon class="ml-2" /> 
      <span class="ml-1 mr-2 uppercase font-semibold">{{current_locale}}</span>
      <!-- <span v-if="$store.getters.cart.length > 0" class="dish-circle w-3 h-3 rounded-full bg-cart_green absolute right-1 top-0 text-xs"></span> -->
    </button>

    <LangSelector v-model="lang_selector" />

    
    <!-- <transition duration="200" name="slide-r">
      <button v-if="cart.length > 0" @click="() => {$router.push('/checkout')}" class="relative flex justify-center items-center border-l-2 border-inactive h-8 cursor-pointer">
        <CartIcon class="m-3" />
        <span v-if="$store.getters.cart.length > 0" class="dish-circle w-3 h-3 rounded-full bg-cart_green absolute right-1 top-0 text-xs"></span>
      </button>
    </transition>

    

    <button v-if="cart.length == 0 && $store.getters.orderguid" @click="() => {$router.push('/checkout')}" class="relative flex justify-center items-center border-l-2 border-inactive h-8 cursor-pointer">
      <PayIcon class="m-3" />
    </button> -->
  
  </div>
</template>

<script>
import BackButton from '../icons/BackIcon.vue'
import LangSelector from './LangSelector.vue'
// import CartIcon from '../icons/CartIcon.vue'
// import PayIcon from '../icons/PayIcon.vue'
// import SearchIcon from '../icons/SearchIcon.vue'
import GlobeIcon from '../icons/GlobeIcon.vue'
import {mapGetters} from 'vuex'


export default {
  data(){
    return {
      lang_selector: false
    }
  },
  watch:{
    cart(){
      this.$nextTick(() => {
        let ic = document.getElementsByClassName('dish-circle')[0]
        if (ic) {
          ic.classList.add('shake_it')
          setTimeout(() => ic.classList.remove('shake_it'), 1500)
        }
      })
    }
  },
  computed:{
    ...mapGetters(['current_locale', 'current_locale_flag', 'webapp_config']),
    breadcrumb(){
      return this.$t(this.$route.matched.reduce((val, el) => {return el.meta.breadcrumb || val}, ''))
    }
  },
  components:{
    BackButton,
    GlobeIcon,
    LangSelector,
    // CartIcon,
    // PayIcon,
    // SearchIcon

  }
  
}
</script>


<style scoped>
.slide-r-enter-active, .slide-r-leave-active {
  transition: all .2s ease-in-out;
}

.slide-r-enter-active {
  transform: translateX(100%);
  opacity: 0;
}

.slide-r-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.dish-circle {
  transition: all 1s ease-in-out;
}

.huge {
  animation: go-huge 1.5s ease-in-out;
}

@keyframes go-huge{
  0%, 100% {
    transform: scale(1) translateX(0) translateY(0);
  }

  20%, 80%{
    transform: scale(2) translateX(-50%) translateY(50%);
  } 
}


</style>