<template>
  <div>
    <div :id="`group${group.id}`" class=" inline-block text-md font-bold mb-4 " :style="`color: ${validity_value ? 'inherit' : $store.getters.c_contrasting}`">{{group.name}}</div>
    <Modifier
      class="mb-3"
      v-for="modifier in group.modifiers"
      :key="modifier.id"
      :behaviour="(modifier.limit == 1) || (group.upper_limit == 1) ? 'switch' : 'counter'"
      :modifier="modifier"
      :modifiers="modifiers"
      @append="el => $emit('append', {group: rebounded_group(el.id), modifier: el})"
      @remove="el => $emit('remove', {group: rebounded_group(el.id), modifier: el})"
    />

  </div>
</template>

<script>
import Modifier from './Modifier.vue'


export default {
  props:['group', 'modifiers'],
  data(){
    return {
      validity_value: false
    }
  },
  watch:{
    modifiers(val){
      this.group.total_count = 0
      this.group.bound_modifiers = val.filter(el => {
        
        if (this.group.modifiers.filter(g_el => g_el.id == el.id).length > 0) {
          this.group.total_count += el.count
          return true
        } else return false
      })

      // this.group.validity = this.group.total_count >= this.group.lower_limit
      this.validity()
    }
  },
  methods:{
    validity(){
      this.validity_value = this.group.total_count >= this.group.lower_limit
      this.group.validity = this.validity_value
      this.$emit('validity', this.group)
    },
    rebounded_group(modi_id){
        
        
        let tmp = {...this.group}

        // tmp.bound_modifiers = []
        // tmp.total_count = 0


        if (!tmp.bound_modifiers){
          tmp.bound_modifiers = []
          tmp.total_count = 0
        } else {
          tmp.total_count = 0
          tmp.bound_modifiers = tmp.bound_modifiers.filter(el => {
            tmp.total_count += el.count
            return el.id != modi_id
          })
        }

        return tmp
    }
  },
  computed:{
    // rebounded_group(){
      
    //   return modi_id => {
        
    //   }
    // }
  },
  components:{
    Modifier,
  }
  
}
</script>