<template>
    <transition-group duration="200" tag="ul" name="shuffler" style="flex flex-col" appear>
      <!-- <div class="flex flex-col"> -->
      <CartItem 
        
        class="mb-5"
        v-for="(portion, idx) in cart" 
        :key="portion.id" 
        :style="`z-index:${idx+1}; background: ${$store.getters.c_background}`"
        :portion="portion"
        @inc="modify_cart({idx, mod:{count:portion.count + 1}})" 
        @dec="modify_cart({idx, mod:{count:portion.count - 1}})"/>
      <!-- </div> -->
    </transition-group>
  
  
</template>

<script>
import CartItem from './CartItem.vue'
import {mapGetters, mapMutations} from 'vuex'

export default {
  methods:{
    ...mapMutations(['modify_cart'])
  },
  computed:{
    ...mapGetters(['cart'])
  },
  components:{
    CartItem
  }
  
}
</script>

<style scoped>
  .shuffler-enter, .shuffler-leave-to,
  .shuffler-leave-active {
    opacity: 0;
    transform: translateY(50%);
  }
  .shuffler-leave-active {
    position: absolute;
  }
</style>