<template>
  <div id="catlink_switch" class="overflow-x-auto flex flex-nowrap rounded-full border-r-2 border-l-2" :style="`border-color: ${$store.getters.c_text_basic}44`">
    <CategSM @click="onCatLinkClick(category.id)" v-for="category in menu_categories" :key="category.id" :category="category"/>
  </div>
</template>

<script>
import CategSM from './CategSM.vue'
import smooth_scroll from '../../mixins/smooth_scroll'
import {mapGetters} from 'vuex'

export default {
  mixins: [smooth_scroll],
  computed:{
    ...mapGetters(['menu_categories']),
  },
  components:{
    CategSM
  },
  watch:{
    async $route(to) {
      if (to.params.categ != undefined) {
        await this.scroll_catlink(to.params.categ)
      }
    }
  },
  methods: {
    onCatLinkClick(cat){
      this.$emit('click', cat)
    }
  }
  
}
</script>

<style>
#catlink_switch::-webkit-scrollbar{
  opacity: 0;
  display: none;
}

.categ-sm:first-child{
  margin-left: -2px;
}

.categ-sm:last-child {
  margin-right: -3px;
}

.categ-sm:not(:last-child) {
  margin-right: 6px;
}
</style>