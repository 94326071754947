import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Menu from '../views/menu/Menu.vue'
// import AliveCategList from '../views/menu/AliveCategList'
// import CategList from '../views/menu/CategList'
import DishVerbose from '../components/dish/DishVerbose'
import ModalFullscreen from '../views/ModalFullscreen'
import ComboModiSchemeView from '../views/menu/ComboModiSchemeView'
import OrderCheckout from '../views/OrderCheckout'
import InvoiceView from '../views/InvoiceView'
import Failed from '../views/Failed'
import Away from '../views/Away'
import TableSelection from '../views/TableSelection'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta:{
      page_index: 0,
      breadcrumb: 'main-page'
    }
  },
  {
    path: '/offer',
    name: "Offer",
    component: () => import('@/views/PublicOffer.vue'),
    meta: {
      page_index: 1,
      back_ref: '/'
    }
  },
  {
    path: '/menu/',
    component: Menu,
    name:"Menu",
    meta:{
      page_index: 1,
      breadcrumb: 'restaurant-menu'
    },
    children:[
      {
        path: 'categ',
        meta:{
          back_ref: '/'
        }
      },
      {
        path: 'categ/:categ',
        meta:{
          back_ref: '/'
        }
      },
      {
        path: 'dish',
        component: ModalFullscreen,
        meta: {
          back_ref: '/menu/categ/',
          ignore_observations: true
        },
        children:[
          {
            path: ':dish',
            name: 'DishVerbose',
            component: DishVerbose,
            meta: {
              back_ref: '/menu/categ/',
              ignore_observations: true
            },
            children:[
              {
                path:'combo',
                component: ModalFullscreen,
                children:[
                  {
                    name: 'ComboModifierView',
                    path: ':combo_modi_scheme',
                    component: ComboModiSchemeView,
                    meta:{
                      ignore_observations: true
                    }
                  }
                ]

              }
            ]
          }

        ]
      }
    ],
    
  },
  {
    path:'/checkout',
    name:'Checkout',
    component: OrderCheckout,
    meta:{
      page_index: 2,
      back_ref: '/menu/categ/'
    },
    children:[
      {
        path: 'table',
        name: 'TableSelection',
        component: ModalFullscreen,
        meta:{
          page_index: 2,
          back_ref: '/checkout'
        },
        children:[
          {
            path: '',
            component: TableSelection,
            meta:{
              page_index: 2,
              back_ref: '/checkout'
            }
          }
        ]
      }
    ]
  },
  {
    path:'/away',
    name:'Away',
    component: Away,
    meta:{
      page_index: 3,
      back_ref: '/checkout'
    }
  },
  {
    path:'/invoice',
    name:'Invoice',
    component: InvoiceView,
    meta:{
      page_index: 3,
      back_ref: '/checkout'
    }
  },
  {
    path:'/failed',
    name:'Failed',
    component: Failed,
    meta:{
      page_index: 3,
      back_ref: '/menu/categ'
    }
  }

]

const router = new VueRouter({
  routes
})

export default router
