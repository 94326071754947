export default {
  data(){
    return {
      flat_header: true
    }
  },
  methods: {
    window_scroll(){
      this.flat_header = window.scrollY <= 10
    },
    element_scroll(e){
      this.flat_header = e.target.scrollTop <= 10
    }
  }
}