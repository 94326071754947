<template>
  <div class="text-sm opacity-80">
    <div class="mb-4"><T>cheque-ro-ordered-before</T></div>

    <ChequeDish v-for="(dish, index) in order.dishes" :key="index" :dish="dish"/>

  </div>
</template>

<script>
import ChequeDish from './ChequeDish.vue'
import {mapGetters} from 'vuex'

export default {
  components:{
    ChequeDish
  },
  computed:{
    ...mapGetters(['order'])
  }
}
</script>