<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <button v-if="debug" @click="load_storage">Load storage</button>
    <QRIcon class="mb-8 fader" />
    <!-- <span class="fader"><T>error-scan-qr-first</T></span> -->
  </div>
</template>

<script>
import QRIcon from "../icons/QRIcon.vue";

export default {
  methods: {
    load_storage() {
      // localStorage.setItem('qrmenu_agent_webapp_config', '{"restaurant_name":"Panda Boy","social_fb":"https://facebook.com","social_vk":"https://vk.com","social_inst":"https://instagram.com","locale":"ru.json","use_online_payments":true,"use_offline_payments":true,"simple_order":false,"entity_name":"asdf","entity_TIN":"asdf","use_tips":true,"manual_table_selection":true,"show_fractional_sums":false,"default_locale":"ru"}')
      localStorage.setItem('qrmenu_agent_webapp_config', '{"restaurant_name":"","social_fb":"","social_vk":"","social_inst":"","locale":"ru.json","use_online_payments":true,"use_offline_payments":true,"simple_order":false,"entity_name":"test1","entity_TIN":"test2","use_tips":false,"show_fractional_sums":false,"default_locale":"ru","available_locales":["ru"],"currency_symbol":"₽","allow_waiter_call":false,"allow_bill_request":true,"allow_call_taxi":false,"call_taxi_link":"","dish_rows":false,"welcome_popup":false,"custom_info":false,"dish_comments":false,"show_vdu_number":false,"use_order_comment":true,"order_comment_is_mandatory":true,"order_comment_field_name":"Tasty Testy"}')
      localStorage.setItem("qrmenu_agent_appuid","ba56d09994629465c9657ef8d7562566");
      localStorage.setItem("qrmenu_agent_tableid", "1000058");
    },
  },
  computed: {
    debug() {
      return process.env.NODE_ENV == "development";
    },
  },
  mounted() {
    if (!this.debug) {
      location.href = "/welcome/redirect";
    }
  },
  components: {
    QRIcon,
  },
};
</script>

<style scoped>
.fader {
  display: flex;
  align-self: center;
  justify-self: center;
  display: inline;
  font-size: 1.8rem;
  animation: fadein 3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>