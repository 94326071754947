<template>
  <div class="mosaic flex flex-wrap xs:flex-row items-center w-full" :class="$store.getters.webapp_config.dish_rows ? 'flex-row' : 'flex-col'">
    <Dish v-for="dish in menu_items" :key="dish.id" :categ="categ" :dish="dish"/>
    <div class="placeholder" v-if="(menu_items.length % 2 != 0)" style="min-width: 170px; flex: 50%;"></div>
  </div>
</template>

<script>

import Dish from './Dish.vue'
import {mapGetters} from 'vuex'

export default {
  props: ['categ'],
  computed:{
    ...mapGetters(['menu_items_of_category']),
    menu_items(){
      return this.menu_items_of_category(this.categ)
    }
  },
  components:{
    Dish
  } 
}
</script>