export default {
    state: {
      socials: []
    },
    mutations: {
      set_socials(state,value) {
        state.socials = value
      }
    },
    actions: {
      async get_socials({dispatch, commit}) {
        try {
          let r = await dispatch('api_request', {'method': '/socials', 'internal_request': false})
          commit('set_socials', r.socials)
        } catch (_) {
          //
        }
      }
    },
    getters: {
        socials(state){
            return state.socials.toSorted((a,b) => a.sort_order - b.sort_order).filter((e) => e.link != "")
        }
    }
}