export default {
  data(){
    return {
      catlink_scrolling_active_promise: undefined,
      catlink_scrolling_active_promise_cancel_condition: false,
      catview_scrolling_promise: []
    }
    
  },
  methods:{
    scroll(controller, targert, smoothness = 100){
      const scroll_shift = 500
      let controller_el = document.getElementById(controller)
      let cancel_condition = false
      
      let tmp = new Promise((resolve, reject) => {
        if (controller_el) {
          let start_x = controller_el.scrollLeft
          let start_y = controller_el.scrollTop

          let target_x = targert.x == undefined ? start_x : targert.x
          let target_y = targert.y == undefined ? start_y : targert.y

          if (Math.abs(target_y - start_y) > scroll_shift) {
            if (target_y > start_y) {
              start_y = target_y - scroll_shift
            } else {
              start_y = target_y + scroll_shift
            }
          }

          
          let easeOutQuart = t => 1-(--t)*t*t*t //timing function
            
          let move = (iter) => {
            if (cancel_condition) {
              x_finished = true
              y_finished = true
            }
            let x_finished = false
            let y_finished = false

            if (Math.abs(controller_el.scrollTop - target_y) > 1) {
              controller_el.scrollTop = start_y + easeOutQuart(iter/smoothness) * (target_y - start_y)
            } else {
              controller_el.scrollTop = target_y
              y_finished = true

            }

            if (Math.abs(controller_el.scrollLeft - target_x) > 1) {
              controller_el.scrollLeft = start_x + easeOutQuart(iter/smoothness) * (target_x - start_x)
            } else {
              controller_el.scrollLeft = target_x
              x_finished = true
            }
            
            if ((iter < smoothness) || (!x_finished && !y_finished)) {
              setTimeout(move, 1, iter + 1)
            } else {
              resolve()
            }
          }

          move(1)
        } else {
          reject(new Error('Scroll control is not found in a DOM tree'))
        }
      })

      tmp.cancel = () => {
        cancel_condition = true
      }

      return tmp
    },
    scrollWindow(targert, smoothness = 100){
      const scroll_shift = 300
      let controller_el = window
      let cancel_condition = false
      
      let tmp = new Promise((resolve, reject) => {
        if (controller_el) {
          let start_x = controller_el.scrollX
          let start_y = controller_el.scrollY
          
          let target_x = targert.x == undefined ? start_x : targert.x
          let target_y = targert.y == undefined ? start_y : targert.y

          if (Math.abs(target_y - start_y) > scroll_shift) {
            if (target_y > start_y) {
              start_y = target_y - scroll_shift
            } else {
              start_y = target_y + scroll_shift
            }
          }

          
          let easeOutQuart = t => 1-(--t)*t*t*t //timing function
            
          let move = (iter) => {
            if (cancel_condition) {
              x_finished = true
              y_finished = true
            }
            let x_finished = false
            let y_finished = false

            let x_current = 0
            let y_current = 0

            if (Math.abs(controller_el.scrollY - target_y) > 1) {
              y_current = start_y + easeOutQuart(iter/smoothness) * (target_y - start_y)
            } else {
              y_current = target_y
              y_finished = true
            }

            if (Math.abs(controller_el.scrollX - target_x) > 1) {
              x_current = start_x + easeOutQuart(iter/smoothness) * (target_x - start_x)
            } else {
              x_current = target_x
              x_finished = true
            }

            // console.log(y_current)

            controller_el.scrollTo(Math.ceil(x_current), Math.ceil(y_current))

            
            if ((iter < smoothness) || (!x_finished && !y_finished)) {
              setTimeout(move, 1, iter + 1)
            } else {
              resolve()
            }
          }

          move(1)
        } else {
          reject(new Error('Scroll control is not found in a DOM tree'))
        }
      })

      tmp.cancel = () => {
        cancel_condition = true
      }

      return tmp
    },
    async do_scroll_catlink(cat, smoothness = 50){
      let target = document.getElementById(`catlink${cat}`)
      let control = document.getElementById('catlink_switch')

      if (target) {
        return await this.scroll('catlink_switch', {
          x: target.offsetLeft - (control.clientWidth / 2) + (target.clientWidth / 2)
        }, smoothness)
      }
    },
    async scroll_catlink(cat, smoothness = 40) {
      if (this.catlink_scrolling_active_promise != undefined) {
        this.catlink_scrolling_active_promise_cancel_condition = true
        await this.catlink_scrolling_active_promise
        this.catlink_scrolling_active_promise_cancel_condition = false
      }

      this.catlink_scrolling_active_promise = this.do_scroll_catlink(cat, smoothness)
      
      return await this.catlink_scrolling_active_promise
    },
    async scroll_catview(cat, smoothness = 100) {
      await this.catview_scrolling_promise

      let target = document.getElementById(`cat${cat}`)
      if (target) {
        this.catview_scrolling_promise = this.scrollWindow({y: target.offsetTop - 120}, smoothness)
        return this.catview_scrolling_promise
      }
    }
  }
}