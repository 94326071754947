<template>
  <div class="w-full">
    <CategViewer v-for="category in menu_categories" :key="category.id" :category="category"/>
    
  </div>
</template>

<script>
import CategViewer from '../../components/categ/CategViewer.vue'
import smooth_scroll from '../../mixins/smooth_scroll'
import {mapGetters} from 'vuex'

export default {
  name: "CategList",
  props:['ignore_observations'],
  mixins: [smooth_scroll],
  computed:{
    ...mapGetters(['menu_categories'])
  },
  data(){
    return {
      categ: 0,
      ticking: false
    }
  },
  watch:{
    // async $route(to) {
    //   if (to.params.categ != undefined) {
    //     await this.$store.dispatch('set_focused_category', to.params.categ)
    //   }
    // }
  },
  methods:{
    active_category(){
      let scroll_root = window //querySelector('#main_dishlist')
      let rendered_categories = document.getElementsByClassName('category-container')


      let closest_element = {delta: Number.MAX_VALUE, element: undefined}

      for (let i = 0; i < rendered_categories.length; i++){
        let current_element = rendered_categories[i]
        let current_offset = scroll_root.scrollY - (current_element.offsetTop - 500)
        //console.log(current_offset)
        
        if ((current_offset > 0) && (current_offset < closest_element.delta)){
          closest_element.delta = current_offset
          closest_element.element = current_element
        }
      }

      //console.log(closest_element.element)

      return closest_element.element
    },
    observe(){
      if (this.ignore_observations == true) {
        return true
      }

      if (!this.ticking) {
        this.ticking = true
        let active_category = this.active_category()
        if (active_category) {
          let active_category_id = active_category.id.replace('cat','')


          if (this.$route.params.categ != active_category_id) {
            this.$router.replace(`${active_category_id}`)
          }
        }

        setTimeout(() => {this.ticking = false}, 300)
      }
    }
  },
  async mounted(){

    // if (this.$route.params.categ) {
      
    //   await Promise.all([
    //     this.scroll_catlink(this.$route.params.categ,1),
    //     this.scroll_catview(this.$route.params.categ, 1)
    //   ])
    // }
    
    window.addEventListener('scroll', this.observe)

    this.$emit('onmounted')
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.observe)
  },
  components:{
    CategViewer
  }
}
</script>