export default {
  state:{
    observer: undefined
  },
  actions:{
    init_observer({state, dispatch}){
      state.observer = new IntersectionObserver((entries) => {
        entries.forEach(el => {
          if (el.intersectionRatio > 0) {
            el.target._observation_callback()
            dispatch('unobserve', el.target)
          }
        })
      }, {root: undefined, threshold: 0.05})
    },
    observe({state}, {element, callback}) {
      element._observation_callback = callback
      state.observer.observe(element)
    },
    unobserve({state}, element) {
      state.observer.unobserve(element)
    }
  }
}