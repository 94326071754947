export default {
  actions:{
    async follow_tiplink(ctx){
      let body = {}
      if (ctx.getters.orderguid) {
        body.order_guid = ctx.getters.orderguid
      }
      let a = await ctx.dispatch('api_request', {
        method: '/tipslink', 
        options:{
          method: 'post', 
          headers: {'content-type':'application/json'}, 
          body: JSON.stringify(body)
        }})
      return a.tipslink  
    }
  }
}