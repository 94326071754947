<template>
  <span v-if="error" style="color:red; font-weight: 700">
    {{`\${${error}\}$`}}
  </span>
  <span v-else-if="phrase && !blockquote" v-html="phrase" />
  <span v-else-if="phrase && blockquote">
    {{phrase}}
  </span>
  <span v-else ref="phrase_proxy">
    <slot/>
  </span>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props:{
    blockquote:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      phrase_code: undefined
    }
  },
  computed:{
    ...mapGetters(['current_locale_retreive_phrase']),
    assigned_phrase(){
      return this.current_locale_retreive_phrase(this.phrase_code)
    },
    phrase(){
      return this.assigned_phrase
    },
    error(){
      if (!this.assigned_phrase){
        return this.phrase_code
      } else return undefined
    }
  },
  mounted(){
    this.phrase_code = this.$refs.phrase_proxy.innerHTML
    // console.log(this.phrase_code)
  }
  
}
</script>

<style scoped>
</style>