<template>
  <div class="w-full">
    <StickyHeader no_padding :flat="flat_header">
      <UtilityHeader />
      <CategSwitcher v-if="!$store.getters.menu_awaiting" @click="categSwitcherClick" class="my-2 mx-1"/>
      <div v-else class="placeholder my-2" style="height: 44px"></div>
    </StickyHeader>
    <!-- <div class="header flex-grow-0 pt-4">
      <div class="">
        
      </div>
    </div> -->
    <ContentLoading class="mt-10 mx-auto" :value="$store.getters.menu_awaiting" />
    <FailScreen :value="$store.getters.menu_failed"/>
    <!-- <VirtualList 
      id="main_dishlist" 
      class="main flex-grow px-3 mt-4 overflow-y-auto w-full flex flex-col items-center"
      :data-key="'id'"
      :data-sources="rendered_menu"
      :data-component="display_proxy"
      :item-class="'w-full mb-5'"
      :wrap-class="'w-full'"
      ref="scroller"
    >
     </VirtualList> --> 
    <div id="main_dishlist" class="main flex-grow overflow-y-auto w-full flex flex-col items-center">
      <CategList @onmounted="catlistMounted" v-if="!$store.getters.menu_awaiting" :ignore_observations="ignore_observations"/>
    </div>

    <OrderButton />

    
    <transition duration="300" name="from-bottom">
      <router-view v-if="!$store.getters.menu_awaiting"/>
    </transition>
  </div>
</template>

<script>

import UtilityHeader from '../../components/UtilityHeader.vue'
import CategSwitcher from '../../components/categ/CategSwitcher.vue'
import CategList from './CategList.vue'
// import Actions from '../../components/footer/Actions.vue'
import smooth_scroll from '../../mixins/smooth_scroll'
import ContentLoading from '../../icons/ContentLoading.vue'
import FailScreen from '../../icons/FailScreen.vue'
import DisplayProxy from './DisplayProxy.vue'
import StickyHeader from '../tools/FixedHeader.vue'
import OrderButton from '../../components/OrderButton.vue'

import flat_header from '../../mixins/flat_header'

// import VirtualList from 'vue-virtual-scroll-list'
import {mapGetters} from 'vuex'

export default {
  mixins:[smooth_scroll, flat_header],
  data(){
    return {
      display_proxy: DisplayProxy,
      observer: undefined,
      ignore_observations: false
    }
  },
  watch:{
    $route(to, from){
      if (to.meta.ignore_observations === true){
        this.ignore_observations = true
      } 
      
      if (from.meta.ignore_observations === true){
        this.ignore_observations = false
      }
    }
  },
  computed:{
    ...mapGetters(['rendered_menu', 'cart', 'order'])
  },
  methods: {
    async catlistMounted(){
      if (this.$route.params.categ) {
        // console.log(this.$route.params.categ)
        // await Promise.all([
        //   this.scroll_catlink(this.$route.params.categ,1),
        //   this.scroll_catview(this.$route.params.categ, 1)
        // ])

        // this.categSwitcherClick(this.$route.params.categ)
        this.scroll_catview(this.$route.params.categ, 100)

        this.scroll_catlink(this.$route.params.categ,100)

        // await Promise.all([
        //   this.categSwitcherClick(this.$route.params.categ),
        //   this.scroll_catlink(this.$route.params.categ,1),
        // ]) 
      }
    },
    async categSwitcherClick(cat){
      this.ignore_observations = true
      
      // await this.$store.dispatch('set_focused_category', cat)
      await this.scroll_catview(cat, 100)
      
      this.ignore_observations = false
      // let idx = 0
      // for (let i = 0; i < this.rendered_menu.length; i++){
      //   if (this.rendered_menu[i].id == cat){
      //     idx = i
      //     break;
      //   }
      // }
      // this.$refs.scroller.scrollToIndex(idx)
      // await this.scroll_catview(cat, 100)
    }
  },
  mounted(){
    if (this.$route.meta.ignore_observations === true) {
      this.ignore_observations = true
    }

    window.addEventListener('scroll', this.window_scroll)
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.window_scroll)
  },
  components:{
    UtilityHeader,
    CategSwitcher,
    CategList,
    FailScreen,
    ContentLoading,
    StickyHeader,
    OrderButton,
  }
  
}
</script>

<style scoped>
.slide-in-enter-active{
  transition: all .3s ease-in-out;
  transform: translateY(100%);
}

.slide-in-enter-to{
  transform: translateY(0);
}

.slide-in-leave-active{
  transition: all .3s ease-in-out;
  transform: translateY(0);
}

.slide-in-leave-to{
  transform: translateY(100%);
}

.from-bottom-enter-active {
  transition: all .3s ease-in-out;
  /*transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0); */
  opacity: .0;
}

.from-bottom-enter-to {
  /* transform: translateY(0%) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-active {
   transition: all .3s ease-in-out;
  /*transform: translateY(0) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-to {
  /* transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0px); */
  opacity: .0;
}

</style>