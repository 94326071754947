<template>
  <router-link :id="`catlink${category.id}`" tag="div" :to="`${category.id}`" replace class="border-2 cursor-pointer categ-sm inline-block rounded-full" active-class="active" :style="`background: ${isactive ? $store.getters.c_carousel_background : 'inherit'}; color: ${isactive ? $store.getters.c_carousel_text : 'inherit'}; border-color: ${isactive ? $store.getters.c_carousel_border : $store.getters.c_text_basic+'44'}`">
    <div class="catlink rounded-full px-3 py-2 whitespace-nowrap" @click="() => $emit('click')"><Locale :strings="category.locale_names" :default="category.name"/></div>
  </router-link>
</template>

<script>
export default {
  props: ['category'],
  computed:{
    isactive(){
      return this.$route.params.categ == this.category.id
      
    }
  }
}
</script>

<style scoped>
.catlink {
  user-select: none;
}
</style>