<template>
  <div class="flex flex-col">
    <T style="font-weigth: 700" :key="51512343">dish-comment</T>

    <textarea 
      :value="value"
      @input.stop="e => $emit('input', e.target.value)"
      :placeholder="$t('dish-comment-placeholder')"  
      maxlength="100" 
      class="comment-area border-2"
      rows="3"
      style="resize: none; padding: .8em; outline: none; transition: all .2s ease-in-out"
      :style="`border-color: ${$store.getters.c_accent}${focused ? `ff` : '60' }; background: ${$store.getters.c_background}`"
      @focus="focused=true"
      @blur="focused=false"
    ></textarea>
  
  </div>
</template>

<script>
export default {
  props: ['value'],
  data: () => ({
    focused: false
  }),
}
</script>

<style scoped>

</style>