<template>
  <div class="mosaic flex flex-row flex-wrap items-center justify-between w-full">
    <Table @select="e => $emit('select', e)" v-for="table in tables" :key="table.id" :table="table" :selection="selection"/>
  </div>
</template>

<script>
import Table from './Table'

export default {
  props:['tables', 'selection'],
  components: {
    Table
  }
}
</script>