<template>
  <div>
    <div class="flex flex-row justify-around items-center ">
      <a v-for="social in socials" :key="social.id" :href="social.link" target="_blank" @click.prevent="showDialog(social.link)">
        <component v-bind:is="social.name"/>
      </a>
    </div>

    <ModalContainerVue v-model="showLeaveDialog">
      <template #header>
        <T>about-to-leave-title</T>
      </template>
      <template>
        <T>about-to-leave-body</T>
        <br/>
        {{link}}
      </template>
      <template #actions>
        <div style="width: 100%;" class="flex justify-between">
          <a :href="link" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`" target="_blank" @click="showLeaveDialog = false">
              <T>leave-confirm</T>
          </a>
          <button @click.prevent="showLeaveDialog = false" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`">
              <T>leave-cancel</T>
          </button>
        </div>
      </template>
    </ModalContainerVue>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Facebook from "./social_icons/Facebook.vue"
import Instagram from "./social_icons/Instagram.vue"
import Odnoklassniki from "./social_icons/Odnoklassniki.vue"
import Site from "./social_icons/Site.vue"
import Telegram from "./social_icons/Telegram.vue"
import TikTok from "./social_icons/TikTok.vue"
import WhatsApp from "./social_icons/WhatsApp.vue"
import YouTube from "./social_icons/YouTube.vue"
import Twitter from "./social_icons/Twitter.vue"
import VK from "./social_icons/VK.vue"

import ModalContainerVue from '../ui/modal/ModalContainer.vue'


export default {
  components:{
    Facebook,
    Instagram,
    Odnoklassniki,
    Site,
    Telegram,
    TikTok,
    Twitter,
    VK,
    WhatsApp,
    YouTube, 
    ModalContainerVue
  },
  computed:{
    ...mapGetters(['socials']),
  },
  methods:{
    showDialog(link){
      this.link = link
      this.showLeaveDialog = true
    }
  },
  data(){ 
    return {
      link: "",
      showLeaveDialog: false,
    }
  }
}
</script>
