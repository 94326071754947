<template>
  <div class="flex z-auto">
    <transition duration="200" appear name="ov">
      <div v-if="main" class="overlay fixed top-0 bottom-0 right-0 left-0 z-10" :style="`background: ${$store.getters.c_background}80`"></div>
    </transition>
    <transition duration="200" appear name="wd">
      <div v-if="delayed" class="window flex flex-col rounded-md fixed z-20" :style="`background: ${$store.getters.c_background}`">
        <div class="header font-bold text-xl p-3">
          <slot name="header">
            <T>modal-header</T>
          </slot>
        </div>

        <hr class="border-1 mx-3 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <div class="content overflow-y-auto px-3">
          <slot/>
        </div>

        <hr class="border-1 mx-3 mt-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">

        <div class="footer flex justify-end items-center">
          <slot name="actions">
            <button class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`" @click="modalResult('ok')">Ok</button>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['value'],
  name:"ModalContainer",
  data(){
    return {
      main: false,
      delayed: false
    }
  },
  methods:{
    modalResult(value){
      this.$emit('modal_result', value)
      this.$emit('input', false)
    },
    setVisibility(value){
      if (value == true) {
        this.main = true
      } else {
        this.delayed = false
      }

      setTimeout(() => {
        if (this.value == true) {
          this.delayed = true
        } else {
          this.main = false
        }
        
      }, 100)
      
    }
  },
  watch:{
    value(val){
      this.setVisibility(val)
    }
  },
  mounted(){
    this.setVisibility(this.value)
  }
}
</script>

<style scoped>



.window {
  max-width: 400px;
  max-height: 300px;
  min-width: 240px;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-box-shadow: 4px 4px 39px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4px 4px 39px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 39px 15px rgba(0, 0, 0, 0.1);
}

button:focus {
  outline: none;
}

.ov-enter-active {
  transition: all .2s ease-in-out;
  opacity: 0;
}

.ov-leave-active {
  transition: all .2s ease-in-out;
  opacity: 1;
}

.ov-leave-to {
  opacity: 0;
}

.ov-enter-to {
  opacity: 1;
}

.wd-enter-active {
  transition: all .2s ease-in-out;
  opacity: 0;
  transform: translate3d(-50%, -30%, 0);
}

.wd-leave-active {
  transition: all .2s ease-in-out;
  opacity: 1;
  transform: translate3d(-50%, -50%, 0);
}

.wd-leave-to {
  opacity: 0;
  transform: translate3d(-50%, -30%, 0);
}

.wd-enter-to {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0);
}




</style>