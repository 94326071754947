<template>
  <div>
    <div v-for="hall in halls" :key="hall.id">
      <div v-if="show_hall_label" class="px-2 mb-4">
        <span class="ml-4 text-xl font-semibold">{{hall.name}}</span>
        <hr class="border-1 mt-1 mb-3" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      </div>

      <TablesMosaic @select="select" :tables="hall.tables" :selection="selection"/>

    </div>
  </div>  
</template>

<script>
import TablesMosaic from './TablesMosaic.vue'

export default {
  props:['halls','show_hall_label', 'selection', 'search_condition'],
  watch:{
    halls(val){
      if (val.length == 1) {
        if (val[0].tables.length == 1){
          this.$emit('select',val[0].tables[0])
        } else this.$emit('select', undefined)
      } else this.$emit('select', undefined)
    }
  },
  methods:{
    select(e){
      this.$emit('select', e)
    }
  },
  components:{
    TablesMosaic
  }
}
</script>

