<template>
  <div :id="`lim${imghash}`" class="h-full w-full flex items-center justify-center" :style="`background: ${$store.getters.c_background}`">
    <transition duration="200" name="fadein">
      <img class="h-full w-full object-cover rounded-md" v-if="tobe_loaded" v-show="loaded" :src="src" alt="" @load="set_loaded"/>
    </transition>
    <ContentLoading :value="tobe_loaded && !loaded" />
  </div>
  
</template>

<script>
import ContentLoading from '../../icons/ContentLoading.vue'

export default {
  props: ['src'],
  data(){
    return {
      loaded: false,
      tobe_loaded: false
    }
  },
  computed:{
    imghash(){
      return Math.random() * 9999999
    }
  },
  components:{
    ContentLoading
  },
  methods:{
    make_to_be_loaded(){
      this.tobe_loaded = true
    },
    set_loaded(){
      this.loaded = true
      setTimeout(()=>{
        this.$emit('loaded')
      }, 200)
      
    }
  },
  mounted(){
    this.$store.dispatch('observe', {element: document.getElementById(`lim${this.imghash}`), callback: this.make_to_be_loaded})
  },
  beforeDestroy(){
    let tmp = document.getElementById(`lim${this.imghash}`)
    if (tmp) this.$store.dispatch('unobserve', tmp)
  }
}
</script>

<style scoped>
.fadein-enter-active{
  transition: all .2s ease-in-out;
  opacity: 0;
}

.fadein-enter-to{
  opacity: 1;
}

</style>