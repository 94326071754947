export default {
  state: {
    popup_fired: true,
    popup_data: {
      header_text: [],
      body_text: [],
      button_text: []
    }
  },
  mutations: {
    set_popup_data(state, value) {
      state.popup_data = value
    },
    set_popup_fired(state, value) {
      state.popup_fired = value
    }
  },
  actions: {
    async load_popup_data({dispatch, commit}, enabled) {
      console.log('load_popup_data enabled', enabled)
      if (enabled) {
        await commit('set_popup_data', await dispatch('api_request', {method: '/popupdata'}))
        await commit('set_popup_fired', false)
      } else {
        await commit('set_popup_fired', true)
      }
    },
    throw_popup({commit, getters}) {
      
      if (!getters.popup_fired) {
        commit('set_popup_fired', true)
        console.log('throw_popup true')
        return true
      } else {
        console.log('throw_popup false')
        return false
      }
    }
  },
  getters: {
    popup_data(state) {
      return state.popup_data
    },
    popup_fired(state) {
      return state.popup_fired
    }
  }
}