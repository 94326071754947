<template>
  <div class="w-full">
    <StickyHeader :flat="flat_header" no_padding>
      <UtilityHeader class="shadow-sm"/>
    </StickyHeader>
    <div id="main_categlist" class="main mt-4 w-full">
      <RestaurantTitle class="mb-4 mx-2" v-if="!$store.getters.menu_awaiting && !$store.getters.menu_failed"/>
      
      <div v-if="!$store.getters.menu_awaiting && $store.getters.webapp_config.custom_info" class="mx-2 italic">
        <hr class="border-1 my-2" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <div class="flex">
          <HintIcon class="mr-2" style="min-width: 24px" />
          <Locale :strings="custom_info.main_page_info" default="" />
        </div>
        <hr class="border-1 my-2" :style="`border-color: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      </div>

      <ContentLoading :value="$store.getters.menu_awaiting" class="my-8 mx-auto"/>
      <FailScreen :value="$store.getters.menu_failed"/>
      <CategMosaic v-if="!$store.getters.menu_awaiting"/>
    </div>
     
    <FixedFooter>
      <Footer class="m-2"/>
    </FixedFooter>

    <ModalContainer v-model="show_welcome_popup">
      <template #header>
        <Locale v-if="show_welcome_popup" :strings="popup_data.header_text" default="Message"/>
      </template>

      <Locale v-if="show_welcome_popup" :strings="popup_data.body_text" default="Welcome to our restaurant!"/>

      <template #actions>
        <button v-if="show_welcome_popup" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`" @click="show_welcome_popup=false"><Locale :strings="popup_data.button_text" default="ok"/></button>
      </template>
    </ModalContainer>
        
      <!-- </div> -->
    <!-- </div>  -->
  </div>
  
</template>

<script>
import UtilityHeader from '../components/UtilityHeader.vue'
import RestaurantTitle from '../components/restaurant_title/RestaurantTitle.vue'
import CategMosaic from '../components/categ/CategMosaic.vue'
import Footer from '../components/footer/Footer.vue'
import ContentLoading from '../icons/ContentLoading.vue'
import FailScreen from '../icons/FailScreen.vue'
import StickyHeader from './tools/FixedHeader.vue'
import FixedFooter from './tools/FixedFooter.vue'
import ModalContainer from '../components/ui/modal/ModalContainer.vue'
import HintIcon from '../icons/HintIcon.vue'

import flat_header from '../mixins/flat_header'
import { mapGetters } from 'vuex'
// import ModalContainer from '../components/ui/modal/ModalContainer.vue'


export default {
  mixins:[flat_header],
  data: () => ({
    show_welcome_popup: false
  }),
  computed: mapGetters(['popup_data', 'custom_info']),
  async mounted(){
    window.addEventListener('scroll', this.window_scroll)

    // await this.$store.dispatch('load_popup_data', this.$store.getters.webapp_config.welcome_popup)

    if (await this.$store.dispatch('throw_popup')) {
      this.show_welcome_popup = true
    }
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.window_scroll)
  },
  components:{
    // ModalContainer,
    FixedFooter,
    StickyHeader,
    FailScreen,
    ContentLoading,
    UtilityHeader,
    RestaurantTitle,
    CategMosaic,
    ModalContainer,
    Footer,
    HintIcon,
  }
  
}
</script>