import { mapGetters } from "vuex"

export default {
  computed: mapGetters(['webapp_config']),
  methods:{
    money(number, decPlaces, decSep, thouSep) {
      decPlaces = this.webapp_config.show_fractional_sums ? 2 : 0
      decSep = typeof decSep === "undefined" ? "." : decSep;
      thouSep = typeof thouSep === "undefined" ? " " : thouSep;
      var sign = number < 0 ? "-" : "";
      var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
      var j = (j = i.length) > 3 ? j % 3 : 0;
  
      return sign +
          (j ? i.substr(0, j) + thouSep : "") +
          i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
          (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "") + ' ' + (this.webapp_config.currency_symbol || ''); // this.$t('cl');
  }
  }
}