<template>
  <div class="tag-container" :class="small ? 'tag-small' : 'tag-normal'">
    <!-- <div class="tag w-full h-full" :style="`background-image: url(${src}); background-size: contain; background-color: ${$store.getters.c_background}`"></div> -->
    <LazyImage :src="src" />
  </div>
</template>

<script>
import LazyImage from '../ui/LazyImage.vue'
import dish_image from '../../mixins/dish_image'

export default {
  mixins: [dish_image],
  props: {
    tag: Object,
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    src(){
      return this.get_icons_link(this.tag.icon)
    }
  },
  components: {
    LazyImage
  }
}
</script>

<style scoped>
.tag-container {
  border-radius: 100%;
  overflow: hidden;
}

.tag-normal {
  width: 40px;
  height: 40px;
}

.tag-small {
  width: 27px;
  height: 27px;
}

.tag-small > .tag {
  box-shadow: 6px -7px 9px 7px #00000033 inset;
  filter: brightness(1.2);
  border-radius: 100%;
}

.tag-normal > .tag {
  box-shadow: 8px -11px 13px 12px #00000033 inset;
  filter: brightness(1.2);
  border-radius: 100%;
}
</style>



<style>

.tag-container + .tag-container {
  /* -webkit-box-shadow: -8px 0px 8px -7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -8px 0px 8px -7px rgba(0, 0, 0, 0.5); */
  box-shadow: -8px 0px 8px -7px rgba(0, 0, 0, 0.5);
}
</style>