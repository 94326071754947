<template>
  <div class="dish-container rounded-md overflow-hidden min-w-min px-1 py-3" :class="{'w-full': !$store.getters.webapp_config.dish_rows}" style="flex: 50%; min-width: 170px">
    <router-link :to="`/menu/dish/${dish.id}/`" class="p-1 rounded-md block relative w-full shadow-md">
      <!-- <span :id="`cathook${dish.category_id}`" class="categ-hook h-1 w-1 block"></span> -->
      <div class="dish-main flex flex-col" style="height: 4.6em;">
        <div class="overflow-hidden" style="height: 2.5em">
          <div class="text-lg font-bold overflow-ellipsis" style="line-height: 1.1em; max-width: 100%"><Locale elipsis :strings="dish.locale_altnames" :default="dish.name"/></div>
        </div>
        <div class="mb-1" style="min-height: 1em">
          <span v-if="dish.volume && dish.volume_measure_unit" class="text-xs text-opacity-50">{{dish.volume}} {{get_volume_label(dish.volume_measure_unit)}}</span>
        </div>
      </div>
      
      
      <!-- <img class="rounded-md object-cover h-36 w-min mb-3"  alt="" :src="`https://picsum.photos/seed/${(new Date()).getTime()}/640/360`"> -->
      <DishImage class="mb-3" 
        v-if="!dish.image_not_required" 
        :sale="dish.sale" 
        :bestseller="dish.bestseller" 
        :is_new="dish.new" 
        :media="dish.image"
        :price="dish.price"
        :previous_price="dish.previous_price"
      />
      

      <div class="mb-3 flex" :style="`min-height: 1.7em; overflow: hidden`">
        <DishTag :tag="tag" v-for="tag in dish.tags" :key="tag.id" small :style="`margin-right: ${.0 - dish.tags.length * .09}em`"/>        
      </div>
      
      <div v-if="dish.image_not_required"  class="text-2xl flex justify-end" :style="`color: ${$store.c_text_basic}`">
        <span class="relative w-auto text-center text-md font-bold" style="text-shadow: #999 1px 1px 0px;">
          <span v-if="dish.previous_price" class="text-sm opacity-80 line-through absolute right-0" style="top:-35%">{{money(dish.previous_price)}}</span>
          {{money(dish.price/100)}}
        </span>
      </div>

      <button class="flex justify-center items-center rounded-md w-full" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <span class="relative my-2 w-auto text-center text-md font-bold">
          <!-- <sup v-if="dish.previous_price" class="absolute text-xs opacity-50 line-through mr-1" style="transform:translateX(-100%); left: -10px; top: 0">{{money(dish.previous_price)}}</sup> -->
          <!-- <span v-if="dish.previous_price" class="text-xs opacity-60 line-through absolute" style="top:-35%">{{money(dish.previous_price)}}</span>
          {{money(dish.price/100)}} -->
          <T :key="dish.id">action-dish-add</T>          
        </span>
      </button>
    </router-link>
  </div>
</template>

<script>

// import HotIcon from '../../icons/HotIcon.vue'
// import VeganIcon from '../../icons/VeganIcon.vue'
// import DietIcon from '../../icons/DietIcon.vue'
// import HalalIcon from '../../icons/HalalIcon.vue'
import DishTag from './DishTag.vue'
import volume_parser from '../../mixins/volume_parser'

import DishImage from './DishImage.vue'

import sum_formatter from '../../mixins/sum_formatter'

export default {
  mixins: [volume_parser, sum_formatter],
  props:['dish'],
  components: {
    DishImage,
    DishTag,
    // HotIcon,
    // VeganIcon,
    // DietIcon,
    // HalalIcon
  }
}
</script>

