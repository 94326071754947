<template>
  <div class="pl-5">
    <div class="text-sm opacity-50">+{{modifier.count}} {{modifier.name}}</div>
  </div>  
</template>

<script>
export default {
  props:['modifier']
}
</script>
