<template>
  <div class="flex items-center" style="width: 110px; min-width: 110px;" :class="{'justify-center': value > 0, 'justify-end': value == 0}">
    <transition duration="400" name="slide-fast">
      <button v-if="value > downlimit" class="flex items-center justify-center h-10 w-10 cursor-pointer rounded-full outline-none" @click="value - 1 >= downlimit ? $emit('dec') : null" :class="{'dimmed': value == downlimit}">
        <MinusIcon class="cursor-pointer"/>
      </button>
    </transition>

    <transition duration="200" name="slide">    
      <div v-if="value > 0" class="text-lg font-bold flex justify-center items-center" style="min-width: 30px">{{value}}</div>
    </transition>

    <button class="flex items-center justify-center h-10 w-10 cursor-pointer rounded-full" @click="value + 1 <= uplimit ? $emit('inc') : null" :class="{'dimmed': value == uplimit}">
      <PlusIcon class="cursor-pointer"/>
    </button>
  </div>
</template>

<script>
import MinusIcon from '../../icons/MinusIcon.vue'
import PlusIcon from '../../icons/PlusIcon.vue'

export default {

  props:{
    value: {
      type: Number,
      default: 0
    },
    uplimit:{
      type: Number,
      default: 10
    },
    downlimit:{
      type: Number,
      default: 0
    }
  },
  components:{
    MinusIcon,
    PlusIcon
  }
  
}
</script>

<style scoped>
  button {
    transition: all .2s ease-in-out;
  }

  button:active, button:focus {
    border: none !important;
    outline: none !important;
    background: none !important;
  }
  .slide-enter-active {
    transition: all .2s ease-in-out;
    transform: translateX(50%);
    opacity: 0;
  }

  .slide-enter-to {
    transform: translateX(0);
    opacity: 1;
  }

  .slide-leave-active {
    transition: all .2s ease-in-out;
    transform: translateX(0);
    opacity: 1;
  }

  .slide-leave-to {
    
    transform: translateX(50%);
    opacity: 0;
  }

  .slide-fast-enter-active {
    transition: all .4s ease-in-out;
    transform: translateX(50%);
    opacity: 0;
  }

  .slide-fast-enter-to {
    transform: translateX(0);
    opacity: 1;
  }

  .slide-fast-leave-active {
    transition: all .1s ease-in-out;
    transform: translateX(0);
    opacity: 1;
  }

  .slide-fast-leave-to {
    
    transform: translateX(50%);
    opacity: 0;
  }

  .dimmed {
    opacity: .4;
  }


</style>