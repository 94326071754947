<template>
  <div class="rounded-md overflow-hidden w-full sm:max-w-xs max-w-sm p-2">
    <router-link :to="`/menu/categ/${button.category_link}`" class="rounded-md block relative w-full">

      <!-- <img class="rounded-md object-cover h-48 w-min"  alt=""> -->
      <CategImage :media="button.image" />
      

      <button class="rounded-b-md absolute left-0 bottom-0 right-0 flex justify-center items-center w-full" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <span class="my-2 w-auto text-center text-md font-bold"><Locale :strings="button.locale_texts" default="??????????"/></span>
      </button>

    </router-link>
  </div>
</template>

<script>
import CategImage from './CategImage.vue'

export default {
  props:['button'],
  components:{
    CategImage
  }
}
</script>

