<template>
  <span :style="elipsis ? `overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;` : ''">{{string}}</span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
      elipsis: {
        type:Boolean,
        default: false
      },
      strings: {
        type: Array
      }, 
      default: {
        type: String
      }
    },
  computed:{
    ...mapGetters(['current_locale']),
    id(){
      return Math.random() * 9999999999999
    },
    string(){
      if (!this.strings) return this.default
      
      return this.strings.reduce((val, el) => {
        return el.lang_code == this.current_locale ? el.string_value ? el.string_value : val : val
      }, this.default)
    }
  }
}
</script>