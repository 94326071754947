<template>
  <div class="w-1/4 mr-2" v-if="!was_error">
    <img @error="on_error" v class="h-full w-full object-cover rounded-md" :src="get_media_link('logo.png')">
  </div>
</template>

<script>
import dish_image from '../../mixins/dish_image'

export default {
  data(){
    return {
      was_error: false
    }
  },
  methods:{
    on_error(){
      this.was_error = true
    }
  },
  mixins:[dish_image]
}
</script>