<template>
  <span class="text-3xl font-bold">
    {{rest_name}}
  </span>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed:{
    ...mapGetters(['webapp_config']),
    rest_name(){
      return this.webapp_config.restaurant_name
    }

  }
  
}
</script>