<template>
  <transition duration="300" name="from-bottom">
    <FixedFooter v-if="cart.length > 0 || $store.getters.orderguid">
      <button @click="$router.push('/checkout')" class="flex justify-around items-center text-xl font-bold w-full py-3 rounded-md" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`" >
          <CartIcon class="stroke-current"/>

          <span v-if="cart.length > 0" class=""><T :key="1">menu-to-cart</T></span>

          <span v-if="cart.length > 0" class="text-2xl">{{money(cart_sum(cart)/100)}}</span>
          
          <span class="" v-if="cart.length == 0 && $store.getters.orderguid"><T :key="2">menu-show-order</T></span>
          <span class="" v-if="cart.length == 0 && $store.getters.orderguid"></span>
          
      </button> 
    </FixedFooter>
  </transition>
</template>

<script>
import CartIcon from '../icons/CartIcon.vue'
import FixedFooter from '../views/tools/FixedFooter.vue'
import sum_counter from '../mixins/sum_counter'
import sum_formatter from '../mixins/sum_formatter'
import {mapGetters} from 'vuex'

export default {
  mixins:[sum_counter, sum_formatter],
  computed:{
    ...mapGetters(['cart', 'order'])
  },
  components:{
    FixedFooter,
    CartIcon
  }
}
</script>