<template>
  <div class="flex justify-center items-center flex-wrap w-full">
    <RestaurantLogo class="mr-1"/>
    <RestaurantName class="flex-grow"/>
  </div>
</template>

<script>
import RestaurantLogo from './RestaurantLogo.vue'
import RestaurantName from './RestaurantName.vue'


export default {
  components:{
    RestaurantLogo,
    RestaurantName
  }
  
}
</script>