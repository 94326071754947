export default {
  methods:{
    get_volume_label(volume_measure_unit) {
      if(volume_measure_unit.id == 1) {
        return this.$t('measure-g')
      } else if (volume_measure_unit.id == 2) {
        return this.$t('measure-ml')
      } else if (volume_measure_unit.id == 3) {
        return this.$t('measure-pcs')
      } else if (volume_measure_unit.id == 4) {
        return this.$t('measure-kg')
      } else if (volume_measure_unit.id == 5) {
        return this.$t('measure-l')
      }
    }
  }
}