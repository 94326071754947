export default {
  state:{
    last_waiter: new Date(),
    last_bill: new Date()
  },
  mutations:{
    drop_waiter(state){
      state.last_waiter = new Date()
    },
    drop_bill(state){
      state.last_bill = new Date()
    }
  },
  getters:{
    last_waiter(state) {
      return state.last_waiter
    },
    last_bill(state) {
      return state.last_bill
    }
  },
  actions:{
    async call_waiter({dispatch, commit, getters}) {
      if (new Date() - getters.last_waiter > 5000) {
        try {
          await dispatch('api_request', {
            method: '/call_waiter',
            options:{
              method: 'post',
              headers: {'content-type': 'application/json'},
              body: JSON.stringify({'table_id': getters.tableid, 'order_guid': getters.orderguid})
            }
          })
          commit('drop_waiter')
        } catch(e) {
          console.error(e)
          dispatch('raise_error', getters.current_locale_retreive_phrase_with_placeholder('error-unexpected-error'))
          
        }
      } else {
        await new Promise(r => setTimeout(r, 2000))
      }
    },
    async request_bill({dispatch, commit, getters}) {
      if (new Date() - getters.last_bill > 5000) {
        try {
          await dispatch('api_request', {
            method: '/bill_request',
            options:{
              method: 'post',
              headers: {'content-type': 'application/json'},
              body: JSON.stringify({'table_id': getters.tableid, 'order_guid': getters.orderguid})
            }
          })
          commit('drop_bill')
        } catch(e) {
          console.error(e)
          dispatch('raise_error', getters.current_locale_retreive_phrase_with_placeholder('error-unexpected-error'))
          
        }
      } else {
        await new Promise(r => setTimeout(r, 2000))
      }
    }
  }
}