export default {
  state:{
    modal_counter: 0
  },
  mutations:{
    modal_counter(state, value){
      if (value < 0){
        value = 0
      } 
      
      if (value == 0) {
        document.body.classList.remove('overflow-y-hidden')
      } else {
        document.body.classList.add('overflow-y-hidden')
      }

      state.modal_counter = value
      
    }
  },

  actions:{
    modal_inc({getters, commit}){
      commit('modal_counter', getters.modal_counter + 1)
    },
    modal_dec({getters, commit}){
      commit('modal_counter', getters.modal_counter - 1)
    }
  },
  getters:{
    modal_counter(state){
      return state.modal_counter
    }
  }
}