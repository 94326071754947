export default {
  state: {
    cart: [],
    validated_order: {},
    invoice: undefined,
    invoice_timer: undefined,
    current_order: {},
    unsaved_comment: ""
  },
  mutations: {
    commit_current_order(state, value) {
      state.current_order = value
    },
    commit_validated(state, value) {
      state.validated_order = value
    },
    commit_cart(state, value) {
      state.cart = value
    },
    append_cart(state, value) {
      state.cart.push(value)
      localStorage.setItem('qrmenu_current_cart', JSON.stringify(state.cart))
    },
    modify_cart(state, {idx, mod}){
      state.cart = state.cart.map((el, index) => {
        if (index == idx) {
          return {
            ...el,
            ...mod
          }
        } else return el
      }).filter(el => el.count > 0)
      
      localStorage.setItem('qrmenu_current_cart', JSON.stringify(state.cart))
    },
    clear_cart(state) {
      localStorage.removeItem('qrmenu_current_cart')
      state.cart = []
    },
    set_invoice(state, invoice){
      state.invoice = invoice
    },
    set_invoice_timer(state, invoice_timer){
      state.invoice_timer = invoice_timer
    },
    clear_invoice_timer(state){
      clearInterval(state.invoice_timer)
      state.invoice_timer = undefined
    },
    modify_order_comment(state, comment){
      state.unsaved_comment = comment.trim()
    }
  },
  actions: {
    follow_paylink(ctx, paylink){

      if (paylink.full_url) {
        // console.log(paylink.full_url)
        window.location.href = paylink.full_url
        
        return 
      }


      let postform = document.createElement('form')
      postform.action = paylink.url
      postform.method = 'POST'


      for (let [k,v] of Object.entries(paylink))
      {
        if (k == 'url') continue
        
        let inp = document.createElement('input')
        inp.type = 'hidden'
        inp.name = k
        inp.value = v
        postform.appendChild(inp)
      }
      document.body.appendChild(postform)
      postform.submit()
    },
    async wait_invoice(ctx){
      if (ctx.getters.invoiceid == undefined) {
        return 0
      }
      
      // INTERVAL
      await ctx.commit('set_invoice_timer', setInterval(async ()=>{
        try {

          let r = await ctx.dispatch('api_request', {
            method: `/invoice/${ctx.getters.invoiceid}`,
            options:{
              method: 'get'
            }
          })
          
          if (r.status != 0) {
            await ctx.commit('orderguid', r.order_guid)
            await ctx.commit('invoiceid', undefined)
            
            await ctx.commit('clear_cart')
            await ctx.commit('set_invoice', r)
          }
        } catch (_) {
          console.log('')
        } finally {
          await ctx.commit('clear_invoice_timer')
        }
        
      },2000))
      // INTERVAL END

      while (ctx.getters.invoice_timer != undefined) {
        await new Promise(r => setTimeout(r, 100))
      }

      return ctx.getters.orderguid
    },
    async perform_invoice(ctx, wait_payment){
      ctx.commit('set_invoice', undefined)
      ctx.commit('clear_invoice_timer')

      let rq = {
        wait_payment,
        invoice:{
          table_id: ctx.getters.tableid,
          order_guid: ctx.getters.orderguid,
          unpaid_sum: ctx.getters.validated.sum,
          session:{
            items: ctx.getters.truncated_cart
          },
          order_comment: ctx.getters.order_comment
        }
      }

      let r = await ctx.dispatch('api_request', {
        method: '/invoice/new',
        options: {
          method: 'post',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(rq)
        }
      })

      ctx.commit('invoiceid', r.id)
      
      if (r.order_guid) {
        ctx.commit('orderguid', r.order_guid)
      }
      ctx.commit("modify_order_comment", "")
      return r
    },
    async check_order(ctx) {
      let order_guid = ctx.getters.orderguid

      let r = await ctx.dispatch('api_request', {
                method: `/order`,
                options:{
                  method: 'post',
                  headers: {'content-type':'application/json'}, 
                  body: JSON.stringify({order_guid})
                }
              })

      ctx.commit('commit_current_order', r)
    },
    async validate_cart(ctx){
      let table_id = ctx.getters.tableid
      let session = {items: ctx.getters.truncated_cart}
      let order_guid = ctx.getters.orderguid

      let r = await ctx.dispatch('api_request', {
        method: '/validate', 
        options:{
          method: 'post', 
          headers: {'content-type':'application/json'}, 
          body: JSON.stringify({table_id, order_guid, session})
        }
      })

      ctx.commit('commit_validated', r)

    },
    modify_order_comment(ctx, comment){
      ctx.commit('modify_order_comment', comment)
    }
  },
  getters: {
    cart(state){
      return state.cart
      //.filter( el => el.count > 0)
    },
    truncated_cart(_, getters){
      return getters.cart.map( el => {
        return {
          id: el.dish.id,
          type: el.dish.type,
          count: el.count,
          comment: el.comment,
          modifiers: el.modifiers.map( m => {
            return {
              id: m.id,
              count: m.count,
              modifiers: m.modifiers
            }
          })
        }
      })
    },
    validated(state) {
      return state.validated_order
    },
    invoice(state) {
      return state.invoice
    },
    invoice_timer(state) {
      return state.invoice_timer
    },
    order(state){
      return state.current_order
    },
    unsaved_comment(state){
      return state.unsaved_comment
    },
    order_comment(state){
      return Object.keys(state.current_order).length != 0 ? state.current_order.order_comment : state.unsaved_comment 
    }
  },
  modules: {
  }
}