<template>
  <transition duration="300" name="lang-fade">
     <div @click.prevent="$emit('input', !value)" v-if="value" class="modal-overlay fixed left-0 right-0 top-0 bottom-0 z-10" :style="`background: ${$store.getters.c_background}A0`">
       <div @click.prevent="()=>{}" class="container relative top-0 m-auto w-full" style="max-width: 675px">
         <ul v-if="locales_ready" class="window absolute p-3 rounded-md" style="right: 10px; top: 55px" :style="`background: ${$store.getters.c_background}`">

           <li class="p-3 text-xl rounded-md" @click="$store.dispatch('set_locale', locale.value)" v-for="locale in selectable_locales" :key="locale.value"><span class="uppercase">{{locale.value}}</span> | {{locale.text}}</li>
           <!-- <li class="p-3 text-3xl rounded-md" @click="$store.dispatch('set_locale', 'bg')">BG | Бългаска</li>
           <li class="p-3 text-3xl rounded-md" @click="$store.dispatch('set_locale', 'en')">EN | English</li> -->
         </ul>
       </div>
     </div>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props:['value'],
  data(){
    return {
      locales_ready: false, 
      locales: []
    }
  },
  watch:{
    value(val){
      if (val) {
        this.$store.dispatch('modal_inc')
      } else {
        this.$store.dispatch('modal_dec')
      }

    }
  },
  computed:{
    ...mapGetters(['webapp_config']),
    selectable_locales(){
      return this.locales.filter(el => {
        if (this.webapp_config.available_locales){
          return !!this.webapp_config.available_locales.find(val => val == el.value)
        } else {
          return el.value == this.webapp_config.default_locale
        }
      })
    }
  },
  async mounted(){
    let r = await this.$store.dispatch('api_request', {method: '/locales', internal_request: true})
    this.locales = r.available_locales
    this.locales_ready = true
  }
}
</script>

<style scoped>

.window {
  -webkit-box-shadow: 4px 4px 40px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 40px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 40px 0px rgba(34, 60, 80, 0.2);
}

.window li:active {
  background-color: rgb(155,155,155);
}

.lang-fade-enter-active {
  transition: all .3s ease-in-out;
  opacity: 0;
}

.lang-fade-enter-to {
  opacity: 1;
}

.lang-fade-leave-active {
  transition: all .3s ease-in-out;
  opacity: 1;
}

.lang-fade-leave-to {
  opacity: 0;
}

.lang-fade-enter-active .window {
  transition: all .3s ease-in-out;
  transform-origin: right;
  transform: scale(0.5) translateX(100%) skewX(30deg);
}

.lang-fade-enter-to  .window {
  transform: scale(1) translateX(0) skewX(0deg);
}

.lang-fade-leave-active  .window {
  transition: all .3s ease-in-out;
  transform-origin: right;
  transform: scale(1) translateX(0) skewX(0deg);
}

.lang-fade-leave-to  .window {
  transform: scale(0.5) translateX(100%) skewX(-30deg);
}
</style>