<template>
  <span @click="e => $emit('click', e)">
    <!-- outlined -->
    <!-- light -->
    <!-- <svg v-if="outlined && !dark" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28 21H14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 28L14 21L21 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> -->

    <!-- outlined -->
    <!-- dark -->
    <!-- v-if="outlined && dark" -->
    <svg  width="42" height="42" viewBox="0 0 42 42" :stroke="$store.getters.c_text_basic" xmlns="http://www.w3.org/2000/svg">
      <path :fill="$store.getters.c_background" d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :fill="$store.getters.c_text_basic" d="M28 21H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M21 28L14 21L21 14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!-- not outlined -->
    <!-- dark || light -->

    <!-- <svg v-if="(!outlined && dark) || (!outlined && !dark )" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 8H1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 15L1 8L8 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> -->
  </span>
  
  
</template>

<script>
export default {
  props:{
    outlined: Boolean,
    dark: Boolean
  }
}
</script>