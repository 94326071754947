export default {
  methods: {
    cart_sum(cart) {
      return cart.reduce((val, el) => {

        return val + this.portion_sum(el)
      }, 0)

    },
    portion_sum(portion) {
      return portion.count * (portion.dish.price + this.modifiers_sum(portion.modifiers))
    },
    modifiers_sum(modifiers) {
      return modifiers.reduce((val, el) => {
        return val + this.modifier_sum(el)
      }, 0)
    },
    modifier_sum(modifier){
      return modifier.count * (modifier.price + this.modifiers_sum(modifier.modifiers))
    }
  }
}