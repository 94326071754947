<template>
  <div @click="$emit('select', table)" :class="{'active': active, 'dimmed': dimmed}" class="table rounded-md overflow-hidden max-w-3 m-2 p-2 relative flex-1">
    <span class="text-xl font-semibold text-center w-full absolute top-3 left-0">{{table.name}}</span>
    <TableIcon class="mt-4 my-0 mx-auto"/>
  </div>
</template>

<script>
import TableIcon from '../../icons/TableIcon.vue'

export default {
  props:['table', 'selection'],

  computed:{
    active(){
      if (this.selection) {
        return this.selection.id == this.table.id
      } else return false
    },
    dimmed(){
      return !!this.selection && !this.active
    }
  },
  components:{
    TableIcon
  }
}
</script>

<style scoped>
.table {
  transition: all .3s;
  /* -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2); */
}

.dimmed {
  opacity: .6;
  transform: scale(.8);
}

.active {
  /* -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; */
  transform: scale(1.1);

  -webkit-box-shadow: 0px 2px 43px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 43px 5px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 43px 5px rgba(34, 60, 80, 0.2);
}


</style>