<template>
  <div id="combo_modifiers_scroller" class="overflow-y-auto h-full w-full">
    <!-- <div class="flex flex-row justify-between items-center head mb-5 mx-2" >
      <button>
        <BackButton outlined dark @click="$emit('reject')"/>
      </button>
    </div> -->

    <FixedHeader>
      <button>
        <BackButton outlined dark @click="$emit('reject')"/>
      </button>
    </FixedHeader>
    <div class="p-3">
      <ModifierSchemeView @validity="el => invalid_modi_groups = set_validity(invalid_modi_groups, el)" @append="on_append_modifiers" @remove="on_remove_modifiers" :scheme_id="+$route.params.combo_modi_scheme" :modifiers="modifiers"/>
    </div>
    <!-- <ModifierSchemeView :scheme_id="$route.params.combo_modi_scheme" :modifiers="modifiers" /> -->
    

    <FixedFooter>
      <button class="flex justify-center items-center text-xl font-bold w-full py-5 rounded-md" @click="buy_click" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <span class="" v-if="modifiers_sum(modifiers) > 0">+{{money(modifiers_sum(modifiers)/100)}}</span>
        <span v-else><T>action-add</T></span>
      </button>   
    </FixedFooter>
  </div>
  
</template>

<script>
import FixedHeader from '../tools/FixedHeader.vue'
import FixedFooter from '../tools/FixedFooter.vue'
import ModifierSchemeView from '../../components/dish/modifiers/ModifierSchemeView.vue'
import BackButton from '../../icons/BackIcon.vue'
import route_back from '../../mixins/route_back'
import modifiers from '../../mixins/modifiers'
import sum_counter from '../../mixins/sum_counter'
import sum_formatter from '../../mixins/sum_formatter'

export default {
  mixins:[
    route_back,
    modifiers,
    sum_counter,
    sum_formatter
  ],
  methods:{
    buy_click(){
      this.check_modifiers('combo_modifiers_scroller')
        .then(() => {
          this.$emit('resolve', this.modifiers)
        })
    }
  },
  mounted(){
    this.modifiers = []
  },
  components:{
    ModifierSchemeView,
    BackButton,
    FixedFooter,
    FixedHeader,
  }
  
}
</script>

<style>


</style>