<template>
  <div v-if="scheme.length > 0">
    <div class="text-lg font-bold mb-4"><T>modifier-scheme-label</T></div>
    <ModifierGroup 
      class="mt-8"
      v-for="group in scheme" 
      :key="group.id" 
      :group="group"
      :modifiers="modifiers"
      @append="el => $emit('append', el)"
      @remove="el => $emit('remove', el)"
      @validity="el => $emit('validity', el)"
    
    />
  </div>
</template>

<script>
import ModifierGroup from './ModifierGroup.vue'
import {mapGetters} from 'vuex'

export default {
  props:['scheme_id', 'modifiers'],
  methods:{
    
  },
  computed:{
    ...mapGetters(['menu_modifiers_of_scheme']),
    scheme(){
      if (this.scheme_id) {
        let tmp = this.menu_modifiers_of_scheme(this.scheme_id)

        if (tmp) {
          return [...tmp]
        } else return []
        
      } else return []
    }
  },
  components:{
    ModifierGroup
  }
  
}
</script>