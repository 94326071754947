<template>
  <div class="block justify-center items-center font-bold rounded-md py-2 px-3" :class="{'text-sm': small, 'max-h-9': small}" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
    <T>tag-new</T>
  </div>
  
</template>

<script>
export default {
  props:{
    small: Boolean
  }
}
</script>

<style scoped>

</style>