<template>
  <div @click="onclick" class="w-full">
    <!-- <input 
      style="display:none !important"
      :id="self_id" 
      :type="group ? 'radio' : 'checkbox'" 
      :name="group" 
      :disabled="disabled || readonly" 
      :checked="val" 
      @click="click" 
      v-model="val"
    > -->
    <label class="switch max-w-full">
      <div class="before" :style="active ? `background: ${$store.getters.c_accent}` : `background: ${$store.getters.c_text_basic}66`"/>
      <div class="after border-2" :style="`border-color: ${$store.getters.c_text_basic}44; background-color: ${$store.getters.c_background}; ${active ? `left: -39px;` : ''}`" />
      <slot/>
    </label>
  </div>
  
  
</template>

<script>
export default {
  data(){
    return {
      // val: false
    }
  },
  props:{
    loading: Boolean,
    disabled: Boolean,
    group: String,
    readonly: Boolean,
    value:Boolean
  },
  watch:{
    value(){
      this.val = this.value
    },
    val(){
      if (!this.readonly) {
        // this.$emit('input', this.val)
      }
    }
  },
  methods:{
    onclick(){
      if (!this.readonly) {
        this.$emit('input', !this.value)
      }
      this.$emit('click', !this.value)
    }
  },
  computed:{
    active(){
      return this.value
    },
    self_id(){
      return Math.floor(Math.random() * 99999999)
    }
  }
}
</script>

<style scoped>
.switch {
  display: inline-block;
  cursor: pointer;
  position: relative;
  line-height: 1.2rem;
  user-select: none;
  margin-left: 55px ;
}



label .before {
  transition: all .2s ease-in-out;
  content: " ";
  position: absolute;
  left: -55px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 44px;
  border-radius: 12px;
}

label .after {
  transition: all .2s ease-in-out;
  content: " ";
  position: absolute;
  left: -55px;
  top: 50%;
  transform: translateY(-50%);
  height: 28px;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
  width: 28px;
  border-radius: 50%;
}

</style>