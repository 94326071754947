<template>
  <div class="h-48">
    <LazyImage :src="get_media_link(media, 'md')" />
  </div>
  
</template>

<script>
import LazyImage from '../ui/LazyImage.vue'
import dish_image from '../../mixins/dish_image'

export default {
  mixins:[dish_image],
  props:['media'],
  components: {
    LazyImage
  }
}
</script>