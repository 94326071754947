<template>
  <div class="mosaic flex flex-col sm:flex-row flex-wrap items-center w-full">
    <CategMD v-for="button in not_empty_categories_of_buttons" :key="button.id" :button="button"/>
  </div>
</template>

<script>


import CategMD from './CategMD.vue'
import {mapGetters} from 'vuex'

export default {
  computed:{
    ...mapGetters(['screen_buttons', 'menu_categories']),
    not_empty_categories_of_buttons(){
      return this.screen_buttons.filter( el => !!this.menu_categories.find(ell => ell.id == el.category_link)) 
    }
  },
  components:{
    // CategXL,
    CategMD
  }
  
}
</script>
