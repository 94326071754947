export default {
  methods:{
    go_back(){
      if (this.$route.meta.back_ref) {
        this.$router.replace(this.$route.meta.back_ref)
      } else {
        this.$router.back()
      }
    }
  }

}