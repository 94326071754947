<template>
  <div class="flex flex-col justify-center items-center h-screen w-full p-3">
    <div class="shadow rounded-md flex flex-col justify-center items-center w-full p-3 mb-3">
      <FailedIcon />
      <span class="text-2xl font-bold" :style="`color: ${$store.getters.c_contrasting}`"><T>error-payment-error</T></span>
      <span class="text-lg font-bold"><T>error-sth-went-wrong</T></span>
      <span class="text-md font-bold"><T>error-whats-next</T></span>
      
      <button @click="clear" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer m-3" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <T>action-clear-cart</T>
      </button>
      <span><T>action-or</T></span>
      <button @click="cart" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer m-3" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
        <T>action-try-pay-again</T>
      </button>
    </div>

    
    
    <RestaurantLogo  class="mb-8"/>
    <Actions class="w-full"/>
    <Socials class="w-1/2 my-8"/>
    <Legal class="w-full" />
  </div>
</template>

<script>
import Actions from '../components/footer/Actions.vue'
import FailedIcon from '../icons/FailedIcon.vue'
import RestaurantLogo from '../components/restaurant_title/RestaurantLogo.vue'
import Socials from '../components/footer/Socials.vue'
import Legal from '../components/footer/Legal.vue'

export default {
   methods:{
    kill_invoice(){
      this.$store.commit('invoiceid', undefined)
    },
    clear(){
      this.kill_invoice()
      this.$store.commit('clear_cart')
      this.$router.push('/')
    },
    cart(){
      this.kill_invoice()
      this.$router.push('/checkout')
    }
  },
  components:{
    FailedIcon,
    RestaurantLogo,
    Socials,
    Legal,
    Actions
  }
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}
</style>