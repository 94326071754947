<template>
  <div class="w-full h-full overflow-y-auto" ref="tables_scroller">
    <transition duration="300" name="from-bottom">
      <FixedHeader v-if="!selected" no_padding :flat="flat_header">
        <div class="flex flex-row justify-between items-center head m-2" >
          <button>
            <BackButton outlined dark @click="go_back"/>
          </button>
          <span class="text-2xl font-bold"><T>select-a-table</T></span>
        </div>

        <div v-if="!loading && !selected" class="mt-4 h-14 flex rounded-xl border-2 items-center mx-1 p-2" :style="`border-color: ${$store.getters.c_accent}`">
          <input ref="input" v-model="input_value" type="number" autofocus="autofocus" maxlength="5" class="text-center font-bold text-3xl m-0 p-4 w-full h-full focus:outline-none" style="background:transparent;"/>
        </div>
      
      </FixedHeader>
    </transition>

    <ContentLoading :value="loading" class="mx-auto mt-10" />

    
    <transition duration="300" name="from-bottom">
      <Halls class="mt-2" v-if="!loading && !selected" :halls="filtered_halls" :show_hall_label="halls.length > 1" :search_condition="input_value" @select="e => {selection = e}" :selection="selection"/>
    </transition>

    <transition duration="300" name="from-bottom">
      <div v-if="!loading && selected_delayed" class="shadow mx-auto mt-32 rounded-md flex flex-col justify-center items-center h-48 w-2/3">
        <!-- <span class="text-2xl font-bold mb-3"><T>invoice-thanks-for-order</T></span> -->
        <SuccessIcon />
        <span class="text-md font-bold mt-3"><T>table-selected</T>{{selection.name}}</span>
      </div>
    </transition>

    <transition duration="300" name="from-bottom">
      <FixedFooter v-if="selection && !selected">
        <button @click="select_table" class="flex items-center justify-center rounded-md font-bold text-xl w-full py-4 cursor-pointer" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
          <T>action-select-a-table</T>{{selection.name}}
        </button>
      </FixedFooter>
    </transition>
  </div>
</template>

<script>
import route_back from '../mixins/route_back'
import FixedHeader from './tools/FixedHeader.vue'
import FixedFooter from './tools/FixedFooter.vue'
import BackButton from '../icons/BackIcon.vue'
import ContentLoading from '../icons/ContentLoading.vue'
import SuccessIcon from '../icons/SuccessIcon.vue'
import Halls from '../components/table/Halls.vue'
import flat_header from '../mixins/flat_header'

import {mapGetters} from 'vuex'

export default {
  mixins:[route_back, flat_header],

  data(){
    return {
      loading: false,
      halls: [],
      selection: undefined,
      selected: false,
      selected_delayed: false,
      input_value: ''
    }
  },
  computed:{
    ...mapGetters(['manual_table']),
    filtered_halls(){
      return this.halls.map(el => {
        return {
          ...el,
          tables: el.tables.filter(el => el.name.startsWith(this.input_value))
        }
      }).filter(el => el.tables.length > 0)
    }
  },
  watch:{
    selected(val){
      setTimeout(() => this.selected_delayed = val, 300)
    }
  },
  methods:{
    select_table(){
      if (this.selection) {
        this.$store.commit('tableid', this.selection.id)
        this.selected = true
        setTimeout(this.go_back, 3000)
      }
    },
    async load_tables(){
      this.loading = true

      let r = await this.$store.dispatch('api_request', {method: '/tables'})
      this.halls = r.halls

      this.loading = false
    }
  },
  async mounted(){
    if (!this.manual_table) {
      this.go_back()
    } else {
      await this.load_tables()

      this.$refs.input.focus()
    }

    this.$refs.tables_scroller.addEventListener('scroll', this.element_scroll)
  },
  beforeDestroy(){
    this.$refs.tables_scroller.removeEventListener('scroll', this.element_scroll)
  },
  components:{
    FixedHeader,
    FixedFooter,
    ContentLoading,
    SuccessIcon,
    Halls,
    BackButton
  }
  
}
</script>

<style scoped>
.from-bottom-enter-active {
  transition: all .3s ease-in-out;
  /*transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0); */
  opacity: .0;
}

.from-bottom-enter-to {
  /* transform: translateY(0%) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-active {
   transition: all .3s ease-in-out;
  /*transform: translateY(0) scale(1); */
  /* backdrop-filter: blur(4px); */
  opacity: 1;
}

.from-bottom-leave-to {
  /* transform: translateY(-10%) scale(.3); */
  /* backdrop-filter: blur(0px); */
  opacity: .0;
}

</style>