export default {
  state: {
    theme: {
      background: '#FFF',
      text_basic: "#343434",
      accent: '#68CB9F',
      text_accent: '#FFF',
      carousel_background: '#E5E7EB',
      carousel_text: "#343434",
      carousel_border: '#E5E7EB',
      contrasting: '#DC2626'
    }
  },
  getters: {
    c_background(state){
      return state.theme.background
    },
    c_text_basic(state){
      return state.theme.text_basic
    },
    c_accent(state){
      return state.theme.accent
    },
    c_text_accent(state){
      return state.theme.text_accent
    },
    c_carousel_background(state){
      return state.theme.carousel_background
    },
    c_carousel_text(state){
      return state.theme.carousel_text
    },
    c_carousel_border(state){
      return state.theme.carousel_border
    },
    c_contrasting(state){
      return state.theme.contrasting
    },
  },
  mutations:{
    set_theme(state, value){
      state.theme = value
    }
  },
  actions: {
    async load_theme({dispatch, commit}){
      let r = await dispatch('api_request', {'method': '/theme'})
      await commit('set_theme', r)
    }
  }
}