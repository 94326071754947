<template>
  <div>
    <Switcher 
      v-if="behaviour == 'switch'" 
      readonly
      :loading="awaiting"
      :disabled="awaiting"
      :value="existance.count > 0"
      @click="switch_it"
    >
      <div class="flex flex-col items-start justify-start">
        <span>{{modifier.name}}</span>
        <span v-if="modifier.price" class="mt-2 text-sm opacity-50">+{{money(modifier.price/100)}}</span>  
      </div>
      
    </Switcher>

    <div v-else class="border-2 flex justify-between" :style="`border-color: ${$store.getters.c_accent}60`">
      <div class="m-3 flex flex-col items-start justify-start">
        <span>{{modifier.name}}</span>
        <span v-if="modifier.price" class="mt-2 text-sm opacity-50">+{{money(modifier.price/100)}}</span>
      </div>
      <Counter :value="this.existance.count" @inc="add" @dec="rm" :downlimit="0" :uplimit="!!modifier.limit ? modifier.limit : 10" :awaiting="awaiting" />
      
    </div>    
  </div>
</template>

<script>
import Counter from '../../ui/Counter.vue'
import Switcher from '../../ui/Switcher.vue'
import sum_formatter from '../../../mixins/sum_formatter'

export default {
  mixins:[sum_formatter],
  props: ['modifier', 'modifiers', 'behaviour'],
   data(){
    return {
      awaiting: false
    }
  },
  methods:{
    switch_it(){
      if (this.existance.count > 0) {
        this.rm()
      } else {
        this.add()
      }
    },
    add(){
      this.awaiting = true
      this.$emit('append', this.existance)      
    },
    rm(){
      this.awaiting = true
      this.$emit('remove', this.existance)
    }
  },
  watch:{
    modifiers(){
      this.awaiting = false
    }
  },
  computed:{
    existance(){
      let tmp = this.modifiers.filter(el => el.id == this.modifier.id)
      if (tmp.length > 0) {
        return tmp[0]
      } else {
        return {
          ...this.modifier,
          modifiers: [],
          submodis_resolved: false,
          count: 0
        }
      }
    }
  },
  components:{
    Counter,
    Switcher
  }
  
}
</script>