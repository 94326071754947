<template>
  <div class="h-40 sm:h-72 xs:h-52 relative w-full">
    <transition :duration="500" name="fade">
      <div v-if="loaded" class="rounded-md absolute shadow w-full h-full left-0 right-0 flex justify-between flex-col align-top p-2">
        <div class="flex justify-end">
          <DiscountIcon class="ml-2" small v-if="sale"/>
          <HITIcon class="ml-2" small v-if="bestseller"/>
          <NewIcon class="ml-2" small v-if="is_new"/>
        </div>
        <div class="text-2xl flex justify-end" style="color: #FFF">
          <span class="relative w-auto text-center text-md font-bold" style="text-shadow: #AAA .1em .1em 8px, #999 1px 1px 0px;">
            <span v-if="previous_price" class="text-sm opacity-80 line-through absolute right-0" style="top:-35%">{{money(previous_price)}}</span>
            {{money(price/100)}}
          </span>
        </div>
      </div>
    </transition>
    

    <LazyImage :src="src" @loaded="() => loaded = true"/>
  </div>
</template>

<script>
import LazyImage from '../ui/LazyImage.vue'
import HITIcon from '../../icons/HITIcon.vue'
import DiscountIcon from '../../icons/DiscountIcon.vue'
import NewIcon from '../../icons/NewIcon.vue'
import dish_image from '../../mixins/dish_image'
import sum_formatter from '../../mixins/sum_formatter'



export default {
  props:['media', 'sale', 'bestseller', 'is_new', 'price', 'previous_price'],
  mixins:[dish_image, sum_formatter],
  data(){
    return {
      loaded: false
    }
  },
  computed:{

    src(){
      return this.get_media_link(this.media, 'md')
    }
  },
  components: {
    LazyImage,
    HITIcon,
    DiscountIcon,
    NewIcon
  }
}
</script>

<style scoped>
.shadow {
  /* -webkit-box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.63) inset;
  -moz-box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.63) inset;
  box-shadow: 0px 52px 96px -41px rgba(0, 0, 0, 0.788) inset; */

  -webkit-box-shadow: 0px 45px 100px -32px rgba(0, 0, 0, 0.19) inset;
  -moz-box-shadow: 0px 45px 100px -32px rgba(0, 0, 0, 0.19) inset;
  box-shadow: 0px 45px 100px -32px rgba(0, 0, 0, 0.19) inset;
}

.fade-enter-active {
  transition: all .5s ease-in-out;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
</style>