<template>
  <div>
    <div class="h-24 flex justify-center items-center" v-if="loading">
      <ContentLoading :value="true" />
    </div>
    <div v-else class="flex flex-col justify-between items-center">
      
      <div class="flex justify-between items-center text-2xl font-bold m-4 w-full">
        <span class=""><T>checkout-current-order</T>:</span>
        <span class="whitespace-nowrap">{{money(cart_sum($store.getters.cart) / 100)}}</span>
      </div>

      <div class="w-full" v-if="validated.sum">
        <div class="flex justify-between items-center text-xl opacity-80">
          <span class=""><T>checkout-to-be-paid</T>:</span>
          <span class="whitespace-nowrap">{{money(validated.sum)}}</span>
        </div>
      </div>

    </div>
    
    
  </div>
</template>

<script>
import sum_counter from '../../mixins/sum_counter'
import sum_formatter from '../../mixins/sum_formatter'
import {mapGetters} from 'vuex'
import ContentLoading from '../../icons/ContentLoading.vue'

export default {
  props:['loading'],
  mixins:[sum_counter, sum_formatter],
  computed: {
    ...mapGetters(['validated', 'order']),
    ordersum(){
      return this.order.sum ? this.order.sum : 0
    }
  },
  components:{
    ContentLoading
  }
  
}
</script>