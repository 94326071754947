<template>
  <div class="flex flex-row justify-between items-center ">
    <span v-if="entity_name" class="text-xs text-center text-opacity-50">{{entity_name}}</span>
    
    <router-link to="/offer" v-if="entity_name && entity_tin" class="text-xs text-center text-opacity-50"><T>public-offer</T></router-link>
    
    <span v-if="entity_tin" class="text-xs text-center text-opacity-50">{{entity_tin}}</span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed:{
    ...mapGetters(['webapp_config']),
    entity_name(){
      return this.webapp_config.entity_name
    },
    entity_tin(){
      return this.webapp_config.entity_TIN
    }
  }
}
</script>

