import Vue from 'vue'
import Vuex from 'vuex'

import menu from './modules/menu'
import cart from './modules/cart'
import tips from './modules/tips'
import lazyimage_driver from './modules/lazyimage_driver'
import locale from './modules/locale'
import modal_counter from './modules/modals_counter'
import waiters from './modules/waiters'
import theme from './modules/theme'
import welcome_popup from './modules/welcome_popup'
import custom_info from './modules/custom_info'
import socials from './modules/socials'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    order_guid: undefined,
    table_id: undefined,
    invoice_id: undefined,
    exceptionObject: {title:'', text:''},
    isExceptionThrown: false
  },
  mutations: {
    commit_error(state, value){
      state.isExceptionThrown = true
      state.exceptionObject = value
    },
    hide_error(state) {
      state.exceptionObject = {title:'', text: ''}
      state.isExceptionThrown = false
    },
    tableid(state, value){
      state.table_id = value
      if ((value == undefined) || (value == null)) {
        localStorage.removeItem('qrmenu_agent_tableid')  
      } else {
        localStorage.setItem('qrmenu_agent_tableid', value)
      }
    },
    orderguid(state, value){
      state.order_guid = value
      if ((value == undefined) || (value == null)) {
        localStorage.removeItem('qrmenu_agent_order_guid')  
      } else {
        localStorage.setItem('qrmenu_agent_order_guid', value)
      }
    },
    invoiceid(state, value){
      state.invoice_id = value
      if ((value == undefined) || (value == null)) {
        localStorage.removeItem('qrmenu_agent_invoice_id')  
      } else {
        localStorage.setItem('qrmenu_agent_invoice_id', value)
      }
    },

  },
  actions: {
    raise_error({commit, getters}, value) {

      commit('commit_error', {
        title: getters.current_locale_retreive_phrase_with_placeholder('error-error'),
        text: value
      })
    },
    load_storage(ctx) {
      ctx.dispatch('init_observer')
      
      if (localStorage.getItem('qrmenu_selected_locale')){
        if (!ctx.getters.webapp_config.available_locales.find(el => el == localStorage.getItem('qrmenu_selected_locale'))) {
          localStorage.removeItem('qrmenu_selected_locale')
          ctx.commit('commit_current_locale', ctx.getters.webapp_config.default_locale || 'ru' )
        } else {
          ctx.commit('commit_current_locale', localStorage.getItem('qrmenu_selected_locale'))
        }
      } else {
        ctx.commit('commit_current_locale', ctx.getters.webapp_config.default_locale || 'ru' )
      }

      if (localStorage.getItem('qrmenu_agent_tableid')) {
        ctx.commit('tableid', localStorage.getItem('qrmenu_agent_tableid'))
      }

      if (localStorage.getItem('qrmenu_agent_order_guid')) {
        ctx.commit('orderguid', localStorage.getItem('qrmenu_agent_order_guid'))
      }

      if (localStorage.getItem('qrmenu_agent_invoice_id')) {
        ctx.commit('invoiceid', localStorage.getItem('qrmenu_agent_invoice_id'))
      }

      if (localStorage.getItem('qrmenu_current_cart')) {
        ctx.commit('commit_cart', JSON.parse(localStorage.getItem('qrmenu_current_cart')))
      }

      
    },
    async api_request(ctx, options) {
      if (!options) {
        throw new Error('options required')
      } else if (!options.method) {
        throw new Error('options.method is not provided')
      } else if (!options.method.startsWith('/')) {
        throw new Error('options.method must have leading "/" (slash)')
      }

      //let url = 'http://127.0.0.1:5501'
      let url = ''
      if (options.internal_request) {
        url = `${ctx.getters.url}${options.method}`
      } else {
        url = `${ctx.getters.url}/proxypass/${ctx.getters.appuid}/api/v4${options.method}`
      }

      let r = await fetch(url, options.options || {})
      r = await r.json()

      if (r.result) {
        return r.data
      } else {
        console.error(options.method, r.error)
        if (r.error.class == 'OrderNotFoundException'){
          ctx.commit('tableid', undefined)
          ctx.commit('orderguid', undefined)
          ctx.dispatch('raise_error', `${r.error.text}</br></br>${ctx.getters.current_locale_retreive_phrase_with_placeholder('error-scan-qr-again')}`)
        }else if (r.error.code < 500) {
          ctx.dispatch('raise_error', r.error.text)
        } else {
          ctx.dispatch('raise_error', ctx.getters.current_locale_retreive_phrase_with_placeholder('error-unexpected-error'))
        }
        
        throw r.error
      }
    }
  },
  getters: {
    url(){
      return process.env.NODE_ENV == 'development' ? 'http://192.168.1.214:5500' : ''
    },
    appuid(){
      return localStorage.getItem('qrmenu_agent_appuid')
    },
    tableid(state){
      return state.table_id
    },
    orderguid(state){
      return state.order_guid
    },
    invoiceid(state){
      return state.invoice_id
    },
    manual_table(){
      if (localStorage.getItem('qrmenu_agent_manual_table')) {
        return true
      } else return false
    },
    order_prohibited(){
      if (localStorage.getItem('qrmenu_agent_order_prohibited')) {
        return true
      } else return false
    },
    webapp_config(){
      if (localStorage.getItem('qrmenu_agent_webapp_config')) {
        return JSON.parse(localStorage.getItem('qrmenu_agent_webapp_config'))
      } else return {}
    },
    isBasicsPassed(state, getters){
      return !!getters.appuid
    },
    order_allowed(state, getters){
      return !!getters.appuid && (!!getters.tableid || !!getters.orderguid) && ! getters.order_prohibited
    },
    isThrownException(state) {
      return state.isExceptionThrown
    },
    thrownException(state) {
      return state.exceptionObject
    }
  },
  modules: {
    menu,
    cart,
    lazyimage_driver,
    tips,
    locale,
    modal_counter,
    waiters,
    theme,
    welcome_popup,
    custom_info,
    socials
  }
})
