<template>
  <div class="header-spacer" ref="spacer">
    <div ref="header" :class="{'shadow': !flat, 'p-2': !no_padding}" class="header fixed w-full top-0 rounded-b-2xl" style="max-width: 657px; z-index: 10" :style="`background: ${$store.getters.c_background}`">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    flat:{
      type: Boolean,
      default: false
    },
    no_padding:{
      type: Boolean,
      default: false
    }
  },
  methods:{
    recalc(){
      this.$refs.spacer.style.height = `${this.$refs.header.clientHeight}px`;
      this.$refs.header.style.maxWidth = `${this.$refs.spacer.parentElement.clientWidth}px`
    }
  },
  mounted(){
    this.recalc()

    window.addEventListener('resize', this.recalc)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.recalc)
  }
  
}
</script>

<style scoped>
.header {
  transition: all .1s ease-in-out;
}

.shadow{
  -webkit-box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.6);
  -moz-box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.6);
  box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.6);
}
</style>