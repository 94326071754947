<template>
  <button @click="e => {if (!loading && !success) $emit('click', e)} " class="rounded-md w-full border-2" style="margin: 7px; max-width: 110px; height: 85px; position: relative" :style="`border-color: ${$store.getters.c_text_basic}44`">
    <!-- <ContentLoading :value="true" style="max-height: 70px"/> -->
    <transition name="fade" duration="1050">
      <SuccessIcon  v-if="success" class="fade-leave-active"/>
      <ContentLoading v-else-if="loading" :value="loading" class="fade-leave-active"/>
      <div v-if="!loading && !success" class="h-20 flex flex-col justify-center items-center fade-leave-active">
        <slot/>
        <span v-if="!loading && !success" class="text-xs font-bold text-center" v-html="text"></span>
      </div>
    </transition>
    <!-- <transition name="fade" duration="100">
      
    </transition> -->

    

    


  </button>
</template>

<script>
import ContentLoading from '../../icons/ContentLoading.vue'
import SuccessIcon from '../../icons/SuccessIcon.vue'
export default {
  props: ['text', 'loading', 'success'],
  components:{
    ContentLoading,
    SuccessIcon
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity .2s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active {
  transition: opacity .2s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}
</style>