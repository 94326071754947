<template>
  <div class="flex flex-col justify-center items-center h-screen w-full p-3">
    <div v-if="loading" class="shadow rounded-md flex flex-col justify-center items-center w-full p-3 mb-3">
      <div class="text-2xl font-bold mb-3"><T :key="1">invoice-wait-a-minute</T></div>
      <ContentLoading :value="true" class="mb-3 justify-self-center"/>
      
      <div class="text-md font-bold w-full"><T :key="2">invoice-waiting-for-status</T></div>
      <div class="text-md font-bold w-full"><T :key="3">invoice-waiting-for-status-comment</T></div>
    </div>

    <div v-else-if="result" class="shadow rounded-md flex flex-col justify-center items-center py-2 w-full">
      <span class="text-2xl font-bold mb-3"><T :key="4">invoice-thanks-for-order</T></span>
      <SuccessIcon />
      <div v-if="ordernum">
        <T>order-number-content</T>
        <div class="text-6xl w-full flex justify-center">
          <div>{{ordernum}}</div>
        </div>
      </div>
      <span class="text-md font-bold mt-3"><T :key="5">invoice-cooking-now</T></span>
    </div>
    
    <div v-else class="shadow rounded-md flex flex-col justify-center items-center w-full p-3">
      <div class="text-2xl font-bold mb-3"><T :key="6">invoice-sth-went-wrong</T></div>
      <div class="text-md mb-3"><T :key="7">invoice-ask-waiter-for-status</T></div>
      
      <div class="flex flex-col font-mono text-sm">
        <span v-if="!!invoice.error && invoice.error.code < 500"><T :key="8">error-error</T>: {{invoice.error.text}}</span>
        <span v-else><T :key="9">error-unexpected-error</T></span>
      </div>
      <!-- <v-card-actions class="justify-center">
        <v-icon x-large color="error">mdi-cloud-alert</v-icon>
      </v-card-actions> -->
    </div>

    

    <button v-if="!loading" @click="$router.push('/')" class="flex items-center justify-center text-white rounded-md font-bold text-xl w-full py-4 cursor-pointer my-3" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      <T>invoice-back-to-menu</T>
    </button>
    <button v-if="!loading && $store.getters.webapp_config.use_tips" @click="followTipsLink" class="flex items-center justify-center text-white rounded-md font-bold text-xl w-full py-4 cursor-pointer mb-8" :style="`background: ${$store.getters.c_accent}; color: ${$store.getters.c_text_accent}`">
      <T>invoice-leave-a-tip</T>
    </button>

    <RestaurantLogo  class="mb-8"/>
    <Actions class="w-full"/>
    <Socials class="w-1/2 my-8"/>
    <Legal class="w-full" />

    <ModalContainer v-model="show_ordernum">
      <template #header>
        <T>order-number-header</T>
      </template>
      <div class="py-3">
        <T>order-number-content</T>
        <div class="text-6xl w-full flex justify-center">
          <div>{{ordernum}}</div>
        </div>
      </div>
    </ModalContainer>

    <ModalContainer v-model="showLeaveDialog">
      <template #header>
        <T>about-to-leave-title</T>
      </template>
      <template>
        <T>about-to-leave-body</T>
        <br/>
        {{tipsLink}}
      </template>
      <template #actions>
        <div style="width: 100%;" class="flex justify-between">
          <a :href="tipsLink" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`" target="_blank" @click="showLeaveDialog = false">
              <T>leave-confirm</T>
          </a>
          <button @click.prevent="showLeaveDialog = false" class="text-xl p-3 font-bold" :style="`color: ${$store.getters.c_accent}`">
              <T>leave-cancel</T>
          </button>
        </div>
      </template>
    </ModalContainer>
    
  </div>
</template>

<script>
// import ChequeRO from '../components/cart/ChequeRO.vue'
// import Summary from '../components/cart/Summary.vue'
// import Paymethod from '../components/cart/Paymethod.vue'
import ModalContainer from '../components/ui/modal/ModalContainer.vue'
import ContentLoading from '../icons/ContentLoading.vue'
import SuccessIcon from '../icons/SuccessIcon.vue'
import RestaurantLogo from '../components/restaurant_title/RestaurantLogo.vue'
import Socials from '../components/footer/Socials.vue'
import Legal from '../components/footer/Legal.vue'
import Actions from '../components/footer/Actions.vue'
import {mapGetters} from 'vuex'

export default {
  components:{
    SuccessIcon,
    ContentLoading,
    RestaurantLogo,
    Socials,
    Legal,
    ModalContainer,
    Actions,
  },
  data(){
    return {
      show_ordernum: false,
      ordernum: '',
      showLeaveDialog: false,
      tipsLink: ""
    }
  },
  methods: {
    async followTipsLink(){
      const link = await this.$store.dispatch('follow_tiplink')
      this.tipsLink = link
      this.showLeaveDialog = true
    }
  },
  computed:{
    ...mapGetters(['invoiceid', 'invoice', 'invoice_timer', 'popup_data', 'orderguid', 'webapp_config']),
    loading(){
      return !this.invoice && !!this.invoice_timer
    },
    result(){
      return this.invoice_timer == undefined && !!this.invoice && !this.invoice.error
    }
  },
  watch:{

  },
  async mounted(){
    if (this.invoice == undefined) {
      let order_guid = await this.$store.dispatch('wait_invoice')

      if (order_guid != undefined && this.webapp_config.show_vdu_number) {
        this.show_ordernum = true
        this.ordernum = this.invoice.queue_number
      }
    } else {
      this.$store.commit('clear_invoice_timer')
      this.$store.commit('clear_cart')
    }
  }
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
</style>