import ModalContainer from '../components/ui/modal/ModalContainer'
import Vue from 'vue'

Vue.component(ModalContainer)

export default {
  components:{
    ModalContainer
  },
  data(){
    return {
      modal_showing: false,
      modal_header: '',
      modal_content: '',
      modal_actions:[],
      modal_result: undefined
    }
  },
  methods:{
    on_modal_result(value){
      this.modal_result = value
    },
    dialog(content, head, actions) {
      return new Promise((resolve, reject) => {
        this.modal_header = head || this.$t('modal-header')
        this.modal_content = content
        this.modal_actions = actions
        this.modal_showing = true

        setInterval(() => {
          if (!this.modal_showing) {
            if (this.modal_result) {
              resolve(this.modal_result)
            } else {
              reject(new Error('Aborted by user'))
            }
          }
        }, 100)
      })
    }
  }
  
}